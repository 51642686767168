import React, { useState, useEffect, useContext } from "react";
import { ChevronDownIcon, ChevronRightIcon } from "lucide-react";
import {
  FiFileText,
  FiMoreVertical,
  FiFolder,
  FiEdit2,
  FiMove,
  FiTrash2,
  FiPlay,
} from "react-icons/fi";
import { LuFolderTree } from "react-icons/lu";
import { KnowledgeBase } from "./KnowledgeBase";
import { AnimatePresence } from "framer-motion";
import { useFolders } from "../Context/FolderContext";
import { useFiles } from "../Context/FileContext";
import Helpers from "../Config/Helpers";
import { useChatsContext } from "../Context/ChatsContext";
import { useNavigate, useParams } from "react-router-dom";
import { ChatAppContext } from "../Context/AppContext";

const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
        <h3 className="text-lg font-semibold mb-2">{title}</h3>
        <p className="text-gray-600 mb-4">{message}</p>
        <div className="flex justify-end gap-2">
          <button
            className="px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 text-sm text-white bg-red-600 hover:bg-red-700 rounded-md"
            onClick={onConfirm}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const CollectionList = ({ isCollapsed }) => {
  const { chat_id } = useParams();
  const [collections, setCollections] = useState([]);
  const [expandedColletions, setExpandedColletions] = useState(false);
  const [expandedDocuments, setExpandedDocuments] = useState(false);
  const [expandedChats, setExpandedChats] = useState(true);
  const [showMenu, setShowMenu] = useState({ type: null, id: null });
  const [selectedItems, setSelectedItems] = useState([]);
  const [showRenameModal, setShowRenameModal] = useState({
    show: false,
    type: null,
    id: null,
    currentName: "",
  });
  const [newName, setNewName] = useState("");
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [showMoveToCollectionModal, setShowMoveToCollectionModal] = useState(false);
  const { openChatWithFile, openChatWithFolder, isKnowledgeBaseOpen, setIsKnowledgeBaseOpen, setShowCollections } = useContext(ChatAppContext);
  const { userChats } = useChatsContext();
  const { folders, deleteFolder, renameFolder, getFolders } = useFolders();
  const { files, moveFiles, deleteFile, renameFile,getFiles } = useFiles() || {};
  useEffect(() => {
    setCollections(folders || []);
  }, [folders]);
  const [searchQuery, setSearchQuery] = useState("");

  const [documents, setDocuments] = useState(
    files.filter((file) => file.folder_id === null) || []
  );
  useEffect(() => {
    setDocuments(files.filter((file) => file.folder_id === null) || []);
  }, [files]);

  const handleFilePlay = (file) => {
    openChatWithFile(file);
  };

  const handleFolderPlay = (folder) => {
    openChatWithFolder(folder);
  };
  const handleMenuToggle = (type, id) => {
    if (showMenu?.type === type && showMenu?.id === id) {
      setShowMenu({ type: null, id: null });
    } else {
      setShowMenu({ type, id });
    }
  };

  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    type: null,
    itemId: null,
    title: "",
    message: "",
    isCollectionFile: false,
  });

  const navigate = useNavigate();
  const { deleteChat, renameChat } = useChatsContext();

  const handleDeleteChat = (chatId, chatTitle) => {
    setConfirmModal({
      isOpen: true,
      type: "chat",
      itemId: chatId,
      title: "Delete Chat",
      message: `Are you sure you want to delete "${chatTitle}"? This action cannot be undone.`,
    });
    setShowMenu({ type: null, id: null });
  };

  const handleChatClick = (chatId) => {
    navigate(`/chat/chatbot/${chatId}`);
    setShowCollections(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMenu?.type && !event.target.closest(".menu-container")) {
        setShowMenu({ type: null, id: null });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showMenu]);

  const handleDeleteCollection = (collectionId) => {
    setConfirmModal({
      isOpen: true,
      type: "collection",
      itemId: collectionId,
      title: "Delete Collection",
      message:
        "Are you sure you want to delete this collection and all its files? This action cannot be undone.",
      isCollectionFile: false,
    });
    setShowMenu({ type: null, id: null });
  };

  const handleDeleteFile = (fileId, fileName, isCollectionFile = false) => {
    setConfirmModal({
      isOpen: true,
      type: "file",
      itemId: fileId,
      title: "Delete File",
      message: `Are you sure you want to delete "${fileName}"? This action cannot be undone.`,
      isCollectionFile,
    });
    setShowMenu({ type: null, id: null });
  };

  const handleConfirmDelete = async () => {
    try {
      let success = false;

      if (confirmModal.type === "collection") {
        success = await deleteFolder(confirmModal.itemId);
        if (success) {
          setCollections((prev) =>
            prev.filter((c) => c.id !== confirmModal.itemId)
          );
        }
      } else if (confirmModal.type === "file") {
        success = await deleteFile(confirmModal.itemId);
        if (success) {
          if (confirmModal.isCollectionFile) {
            setCollections((prev) =>
              prev.map((collection) => ({
                ...collection,
                files:
                  collection.files?.filter(
                    (f) => f.id !== confirmModal.itemId
                  ) || [],
              }))
            );
          } else {
            setDocuments((prev) =>
              prev.filter((d) => d.id !== confirmModal.itemId)
            );
          }
        }
      } else if (confirmModal.type === "chat") {
        success = await deleteChat(confirmModal.itemId);
        if (success) {
          // Chat list will be updated through context
          Helpers.toast("success", "Chat deleted successfully");
        }
      }

      if (success) {
        Helpers.toast(
          "success",
          `${
            confirmModal.type === "collection"
              ? "Collection"
              : confirmModal.type === "chat"
              ? "Chat"
              : "File"
          } deleted successfully`
        );
        navigate("/chat/chat-interface");
      }
    } catch (error) {
      console.error("Error during deletion:", error);
      Helpers.toast("error", "Failed to delete item");
    } finally {
      setConfirmModal({
        isOpen: false,
        type: null,
        itemId: null,
        title: "",
        message: "",
        isCollectionFile: false,
      });
      setShowMenu({ type: null, id: null });
    }
  };

  const [expandedFolders, setExpandedFolders] = useState(new Set());

  const toggleFolder = (folderId, e) => {
    e.stopPropagation();
    const newExpanded = new Set(expandedFolders);
    if (newExpanded.has(folderId)) {
      newExpanded.delete(folderId);
    } else {
      newExpanded.add(folderId);
    }
    setExpandedFolders(newExpanded);
  };

  const RenameModal = () => (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-4 max-w-sm w-full mx-4">
        <h3 className="text-lg font-semibold mb-2">
          Rename {showRenameModal.type}
        </h3>
        <input
          type="text"
          className="w-full p-2 border rounded-md mb-4"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          placeholder={`Enter new ${showRenameModal.type} name`}
          autoFocus
        />
        <div className="flex justify-end gap-2">
          <button
            className="px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded-md"
            onClick={() => {
              setShowRenameModal({
                show: false,
                type: null,
                id: null,
                currentName: "",
              });
              setNewName("");
            }}
          >
            Cancel
          </button>
          <button
            className="px-3 py-1.5 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded-md"
            onClick={handleRename}
            disabled={!newName.trim()}
          >
            Rename
          </button>
        </div>
      </div>
    </div>
  );
  const MoveToCollectionModal = () => (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-4 max-w-sm w-full mx-4">
        <h3 className="text-lg font-semibold mb-4">Move to Collection</h3>
        <select
          className="w-full p-2 border rounded-md mb-4"
          value={selectedCollection || ""}
          onChange={(e) => setSelectedCollection(e.target.value)}
        >
          <option value="">Select a collection</option>
          {collections.map((collection) => (
            <option key={collection.id} value={collection.id}>
              {collection.name}
            </option>
          ))}
        </select>
        <div className="flex justify-end gap-2">
          <button
            className="px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded-md"
            onClick={() => {
              setShowMoveToCollectionModal(false);
              setSelectedCollection(null);
            }}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded-md"
            onClick={handleBulkMove}
            disabled={!selectedCollection}
          >
            Move
          </button>
        </div>
      </div>
    </div>
  );

  const handleBulkMove = async () => {
    if (!selectedCollection || selectedItems.length === 0) return;

    try {
      const success = await moveFiles(selectedItems, selectedCollection);

      if (success) {
        setSelectedItems([]);
        setShowMoveToCollectionModal(false);
        setSelectedCollection(null);
        await getFolders();
        await getFiles();

        // Use the updated state values after they are set
        setCollections((prevFolders) => {
          return prevFolders || [];
        });

        setDocuments((prevFiles) => {
          return prevFiles || [];
        });
        Helpers.toast("success", "Files moved successfully!");
      }
    } catch (error) {
      console.error("Error moving files:", error);
      Helpers.toast("error", "Failed to move files");
    }
  };
  const handleRename = async () => {
    const { type, id } = showRenameModal;
    const trimmedName = newName.trim();

    if (!trimmedName) return;

    try {
      let success = false;

      if (type === "chat") {
        success = await renameChat(id, trimmedName);
      } else if (type === "collection") {
        success = await renameFolder(id, trimmedName);
      } else if (type === "document") {
        success = await renameFile(id, trimmedName);
      }

      if (success) {
        Helpers.toast(
          "success",
          `${
            type === "collection"
              ? "Collection"
              : type === "chat"
              ? "Chat"
              : "File"
          } renamed successfully`
        );
        setShowRenameModal({
          show: false,
          type: null,
          id: null,
          currentName: "",
        });
        setNewName("");
        setShowMenu({ type: null, id: null });
      }
    } catch (error) {
      console.error(`Error renaming ${type}:`, error);
      Helpers.toast("error", `Failed to rename ${type}`);
    }
  };
  const filteredCollections =
    collections?.filter(
      (collection) =>
        collection.name
          .toLowerCase()
          .includes((searchQuery || "").toLowerCase()) ||
        collection.files?.some((file) =>
          file.name.toLowerCase().includes((searchQuery || "").toLowerCase())
        )
    ) || [];

  const filteredDocs =
    documents?.filter((doc) =>
      doc?.name?.toLowerCase().includes((searchQuery || "").toLowerCase())
    ) || [];
  const filteredChats =
    userChats?.filter((chat) =>
      chat?.title?.toLowerCase().includes((searchQuery || "").toLowerCase())
    ) || [];

  const renderFileMenu = (file, isCollectionFile = false) => (
    <div className="absolute right-0 top-8 bg-white border border-gray-200 rounded-lg shadow-lg z-50 min-w-[150px]">
      <button
        className="w-full p-2 text-sm hover:bg-gray-100 text-left flex items-center gap-2"
        onClick={() => handleFilePlay(file)}
      >
        <FiPlay className="h-4 w-4" /> Start Chat
      </button>
      <button
        className="w-full p-2 text-sm hover:bg-gray-100 text-left flex items-center gap-2"
        onClick={(e) => {
          e.stopPropagation();
          setShowRenameModal({
            show: true,
            type: "document",
            id: file.id,
            currentName: file.name,
          });
          setNewName(file.name);
          setShowMenu({ type: null, id: null });
        }}
      >
        <FiEdit2 className="h-4 w-4" />
        Rename
      </button>
      <button
        className="w-full p-2 text-sm hover:bg-gray-100 text-left flex items-center gap-2"
        onClick={(e) => {
          e.stopPropagation();
          setSelectedItems([file.id]);
          setShowMoveToCollectionModal(true);
          setShowMenu({ type: null, id: null });
        }}
      >
        <FiMove className="h-4 w-4" />
        Move
      </button>
      <button
        className="w-full p-2 text-sm text-red-500 hover:bg-gray-100 text-left flex items-center gap-2"
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteFile(file.id, file.name, isCollectionFile);
        }}
      >
        <FiTrash2 className="h-4 w-4" />
        Delete
      </button>
    </div>
  );

  const renderCollectionMenu = (collection) => (
    <div className="absolute right-0 top-8 bg-white border border-gray-200 rounded-lg shadow-lg z-50 min-w-[150px]">
      <button
        className="w-full p-2 text-sm hover:bg-gray-100 text-left flex items-center gap-2"
        onClick={() => handleFolderPlay(collection)}
      >
        <FiPlay className="h-4 w-4" /> Start Chat
      </button>
      <button
        className="w-full p-2 text-sm hover:bg-gray-100 text-left flex items-center gap-2"
        onClick={(e) => {
          e.stopPropagation();
          setShowRenameModal({
            show: true,
            type: "collection",
            id: collection.id,
            currentName: collection.name,
          });
          setNewName(collection.name);
          setShowMenu({ type: null, id: null });
        }}
      >
        <FiEdit2 className="h-4 w-4" />
        Rename
      </button>
      <button
        className="w-full p-2 text-sm text-red-500 hover:bg-gray-100 text-left flex items-center gap-2"
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteCollection(collection.id);
        }}
      >
        <FiTrash2 className="h-4 w-4" />
        Delete
      </button>
    </div>
  );
  const renderChatMenu = (chat) => (
    <div className="absolute right-0 top-8 bg-white border border-gray-200 rounded-lg shadow-lg z-50 min-w-[150px]">
      <button
        className="w-full p-2 text-gray-400 hover:text-gray-600 text-sm hover:bg-gray-100 text-left flex items-center gap-2"
        onClick={(e) => {
          e.stopPropagation();
          setShowRenameModal({
            show: true,
            type: "chat",
            id: chat.id,
            currentName: chat.title,
          });
          setNewName(chat.title);
          setShowMenu({ type: null, id: null });
        }}
      >
        <FiEdit2 className="h-4 w-4" />
        Rename
      </button>
      <button
        className="w-full p-2 text-sm text-red-500 hover:bg-gray-100 text-left flex items-center gap-2"
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteChat(chat.id, chat.title);
        }}
      >
        <FiTrash2 className="h-4 w-4" />
        Delete
      </button>
    </div>
  );

  const renderChatItem = (chat) => (
    <div
      key={chat.id}
      className="relative group cursor-pointer"
      onClick={() => handleChatClick(chat.chatid)}
    >
      <div
        className={`flex items-center justify-between p-2 rounded-md hover:bg-[#502EC4] ${
          chat.chatid === chat_id
            ? "bg-[#502EC4] text-white font-semibold"
            : ""
        }`}
      >
        <div className="flex gap-2 flex-1 min-w-0">
          <FiFileText
            className={`h-4 w-4 text-gray-500 flex-shrink-0 ${
              chat.chatid === chat_id ? " text-white" : ""
            }`}
          />
          <span className="truncate text-sm overflow-hidden">{chat.title}</span>
        </div>
        <div className="flex items-center relative flex-shrink-0 menu-container">
          <button
            className="h-6 w-6 flex items-center justify-center rounded-full hover:bg-gray-200"
            onClick={(e) => {
              e.stopPropagation();
              handleMenuToggle("chat", chat.id);
            }}
          >
            <FiMoreVertical
              className={`h-4 w-4  ${
                chat.chatid === chat_id ? " text-white" : ""
              }`}
            />
          </button>
          {showMenu?.type === "chat" &&
            showMenu?.id === chat.id &&
            renderChatMenu(chat)}
        </div>
      </div>
    </div>
  );

  return (
    <div className="space-y-1">
      <button
        className="btn btn-outline-secondary flex items-center gap-2 w-full text-start "
        onClick={() => setIsKnowledgeBaseOpen(true)}
      >
        <LuFolderTree className="text-lg" />
        {!isCollapsed && <span>Document Vault</span>}
      </button>

      {!isCollapsed && (
        <>
          <div className="relative">
            <input
              type="text"
              placeholder="Quick Search"
              className="w-full p-2 rounded-lg border text-sm"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div>
            <div className="flex items-center justify-between py-1">
              <h3
                className="text-gray-900 font-semibold cursor-pointer flex items-center gap-2"
                onClick={() => setExpandedColletions(!expandedColletions)}
              >
                {expandedColletions ? (
                  <ChevronDownIcon className="h-3.5 w-3.5" />
                ) : (
                  <ChevronRightIcon className="h-3.5 w-3.5" />
                )}
                Collections
              </h3>
            </div>

            {expandedColletions && (
              <div className="pl-2 space-y-1">
                {filteredCollections.map((collection) => (
                  <div key={collection.id} className="space-y-1">
                    <div className="relative">
                      <div
                        className="flex items-center justify-between p-2 rounded-md hover:bg-gray-100 cursor-pointer"
                        onClick={(e) => toggleFolder(collection.id, e)}
                      >
                        <div className="flex items-center gap-2 flex-1 min-w-0">
                          <span className="flex-shrink-0">
                            {expandedFolders.has(collection.id) ? (
                              <ChevronDownIcon className="h-4 w-4 text-gray-500" />
                            ) : (
                              <ChevronRightIcon className="h-4 w-4 text-gray-500" />
                            )}
                          </span>
                          <FiFolder className="h-4 w-4 text-gray-500 flex-shrink-0" />
                          <span className="truncate text-sm overflow-hidden">
                            {collection.name}
                          </span>
                        </div>
                        <div className="flex items-center relative flex-shrink-0 menu-container">
                          <button
                            className="h-6 w-6 flex items-center justify-center rounded-full hover:bg-gray-200"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMenuToggle(
                                "collection-menu",
                                collection.id
                              );
                            }}
                          >
                            <FiMoreVertical className="h-4 w-4 text-gray-500" />
                          </button>
                          {showMenu?.type === "collection-menu" &&
                            showMenu?.id === collection.id &&
                            renderCollectionMenu(collection)}
                        </div>
                      </div>
                    </div>

                    {expandedFolders.has(collection.id) && (
                      <div className="pl-4 space-y-1">
                        {collection.files?.map((file) => (
                          <div key={file.id} className="relative group">
                            <div className="flex items-center justify-between p-2 rounded-md hover:bg-gray-100">
                              <div className="flex items-center gap-2 flex-1 min-w-0">
                                <FiFileText className="h-4 w-4 text-gray-500 flex-shrink-0" />
                                <span className="truncate text-sm overflow-hidden">
                                  {file.name}
                                </span>
                              </div>
                              <div className="flex items-center relative flex-shrink-0 menu-container">
                                <button
                                  className="h-6 w-6 flex items-center justify-center rounded-full hover:bg-gray-200"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleMenuToggle("file", file.id);
                                  }}
                                >
                                  <FiMoreVertical className="h-4 w-4 text-gray-500" />
                                </button>
                                {showMenu?.type === "file" &&
                                  showMenu?.id === file.id &&
                                  renderFileMenu(file, true)}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div>
            <h3
              className="text-gray-900 font-semibold cursor-pointer flex items-center gap-2 mb-2"
              onClick={() => setExpandedDocuments(!expandedDocuments)}
            >
              {expandedDocuments ? (
                <ChevronDownIcon className="h-4 w-4 text-gray-500" />
              ) : (
                <ChevronRightIcon className="h-4 w-4 text-gray-500" />
              )}
              Documents
            </h3>

            {expandedDocuments && (
              <div className="pl-2 space-y-1">
                {filteredDocs.map((doc) => (
                  <div key={doc.id} className="relative group">
                    <div className="flex items-center justify-between p-2 rounded-md hover:bg-gray-100">
                      <div className="flex items-center gap-2 flex-1 min-w-0">
                        <FiFileText className="h-4 w-4 text-gray-500 flex-shrink-0" />
                        <span className="truncate text-sm overflow-hidden">
                          {doc.name}
                        </span>
                      </div>
                      <div className="relative menu-container">
                        <button
                          className="h-6 w-6 flex items-center justify-center rounded-full hover:bg-gray-200 flex-shrink-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleMenuToggle("document", doc.id);
                          }}
                        >
                          <FiMoreVertical className="h-4 w-4 text-gray-500" />
                        </button>
                        {showMenu?.type === "document" &&
                          showMenu?.id === doc.id &&
                          renderFileMenu(doc, false)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div>
            <h3
              className="text-gray-900 font-semibold cursor-pointer flex items-center gap-2 mb-2"
              onClick={() => setExpandedChats(!expandedChats)}
            >
              {expandedChats ? (
                <ChevronDownIcon className="h-4 w-4 text-gray-500" />
              ) : (
                <ChevronRightIcon className="h-4 w-4 text-gray-500" />
              )}
              Chats history
            </h3>

            {expandedChats && (
              <div className="pl-2 space-y-1">
                {filteredChats.map((chat) => renderChatItem(chat))}
              </div>
            )}
          </div>
        </>
      )}

      {/* Add Modals */}
      {showRenameModal.show && <RenameModal />}
      {showMoveToCollectionModal && <MoveToCollectionModal />}

      {/* Existing Confirmation Modal */}
      <ConfirmationModal
        isOpen={confirmModal.isOpen}
        onClose={() =>
          setConfirmModal({
            ...confirmModal,
            isOpen: false,
            type: null,
            itemId: null,
            title: "",
            message: "",
            isCollectionFile: false,
          })
        }
        onConfirm={handleConfirmDelete}
        title={confirmModal.title}
        message={confirmModal.message}
      />

      <AnimatePresence>
        {isKnowledgeBaseOpen && (
          <KnowledgeBase
            isOpen={isKnowledgeBaseOpen}
            onClose={() => setIsKnowledgeBaseOpen(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default CollectionList;
