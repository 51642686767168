import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';


// Create styles based on the desired layout
const styles = StyleSheet.create({
  page: {
    padding: 40,
    backgroundColor: '#fff',
    color: '#333',
  },
  container: {
    margin: '0 auto',
    borderTop: '8px solid #666',
    borderBottom: '2px solid #666',
  },
  header: {
    marginBottom: 20,
    paddingBottom: 15,
    borderBottom: '1px solid #ccc',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerText: {
    fontSize: 48,
    textTransform: 'uppercase',
    letterSpacing: 2,
    marginBottom: 10,
  },
  profileText: {
    fontSize: 20,
    fontStyle: 'italic',
  },
  contactInfo: {
    fontSize: 12,
    textAlign: 'right',
  },
  section: {
    marginBottom: 20,
    paddingBottom: 15,
    borderBottom: '1px solid #ccc',
  },
  sectionTitle: {
    fontSize: 18,
    textTransform: 'uppercase',
    letterSpacing: 1,
    borderBottom: '1px solid #ccc',
    paddingBottom: 5,
    marginBottom: 10,
  },
  job: {
    marginBottom: 15,
    paddingBottom: 10,
    borderBottom: '1px solid #ccc',
  },
  jobTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  jobYears: {
    fontSize: 12,
    fontStyle: 'italic',
  },
  description: {
    fontSize: 12,
    marginBottom: 5,
  },
  educationItem: {
    marginBottom: 20,
    fontSize: 14,
  },
  last: {
    borderBottom: 0,
  },
  skillsList: {
    listStyleType: 'none',
    paddingLeft: 0,
  },
  skillItem: {
    marginBottom: 5,
    fontSize: 12,
  },
  talent: {
    marginBottom: 10,
    fontSize: 14,
  },
});

// PDF Template Component with desired structure
const MyPdfTemplate = ({ data }) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.container}>
        {/* Header Section */}
        <View style={styles.header}>
          <View>
            <Text style={styles.headerText}>{data.name}</Text>
            <Text style={styles.profileText}>{data.profile}</Text>
          </View>
          <View style={styles.contactInfo}>
            <Text>{data.email}</Text>
            <Text>{data.phone}</Text>
          </View>
        </View>

        {/* Profile Section */}
        {/* <View style={styles.section}>
          <Text style={styles.sectionTitle}>Profile</Text>
          <Text>{data?.profileDescription}</Text>
        </View> */}

        {/* Skills Section */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Skills</Text>
          {data.skills.map((skill, index) => (
            <View key={index} style={styles.talent}>
              <Text style={styles.jobTitle}>{skill.title}</Text>
              <Text style={styles.description}>{skill.description}</Text>
            </View>
          ))}
        </View>

        {/* Technical Skills Section */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Technical Skills</Text>
          {data.technical_skills.map((skillSet, index) => (
            <View key={index} style={styles.skillsList}>
              {skillSet.map((skill, subIndex) => (
                <Text key={subIndex} style={styles.skillItem}>{skill}</Text>
              ))}
            </View>
          ))}
        </View>

        {/* Experience Section */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Experience</Text>
          {data.experience.map((job, index) => (
            <View key={index} style={styles.job}>
              <Text style={styles.jobTitle}>{job.company}</Text>
              <Text style={styles.jobYears}>{job.position} ({job.years})</Text>
              <Text style={styles.description}>{job.description}</Text>
            </View>
          ))}
        </View>

        {/* Education Section */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Education</Text>
          {data.education.map((edu, index) => (
            <View key={index} style={[styles.educationItem, index === data.education.length - 1 ? styles.last : {}]}>
              <Text style={styles.jobTitle}>{edu.institution}</Text>
              <Text style={styles.jobYears}>{edu.degree} ({edu.years})</Text>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

export default MyPdfTemplate;
