import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import Helpers from "../Config/Helpers";
import { RecursiveCharacterTextSplitter } from "langchain/text_splitter";
import { createClient } from "@supabase/supabase-js";
import { SupabaseVectorStore } from "@langchain/community/vectorstores/supabase";
import { OpenAIEmbeddings } from "@langchain/openai";
import { useApi } from "./ApiContext";

const FileContext = createContext();

export const FileProvider = ({ children }) => {
  const [files, setFiles] = useState([]);
  const { apiSettings } = useApi();

  const getFiles = async () => {
    try {
      const response = await axios.get(
        `${Helpers.apiUrl}files`,
        Helpers.authHeaders
      );
      setFiles(response.data);
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };

  const uploadFiles = async (files, folderId = null) => {
    try {
      let vectorTokenCount = 0;
      for (const file of files) {
        try {
          const formData = new FormData();
          formData.append("file", file);
          if (folderId) {
            formData.append("folder_id", folderId);
          }
          const response = await axios.post(
            `${Helpers.apiUrl}files/upload`,
            formData,
            Helpers.authFileHeaders
          );
    

          const contentId = response.data.file.id;
          const chatContent = response.data.file_content;

          const tokens = await performVectorization(chatContent, contentId);
          vectorTokenCount += tokens;

        } catch (fileError) {
          console.error("Error processing file:", file.name, fileError);
          Helpers.toast("error", `Failed to upload file: ${file.name}`);
        }
      }
   await axios.post(
      `${Helpers.apiUrl}updateTokens`,
      { tokens: vectorTokenCount },
      Helpers.authHeaders
    );
      getFiles();
      return true;
    } catch (error) {
      console.error("Error uploading files:", error);
      Helpers.toast("error", "Failed to upload files.");
      return false;
    }
  };

  const performVectorization = async (chatData, contentId) => {
    if (!contentId) {
      console.error("Content ID is missing or undefined:", contentId);
      return;
    }

    const splitter = new RecursiveCharacterTextSplitter({
      chunkSize: 500,
      chunkOverlap: 50,
      separators: ["\n\n", "\n", " ", ""],
    });

    const output = await splitter.createDocuments([chatData]);
    let tokenCount = 0;

    output.forEach((chunk) => {
      tokenCount += chunk.pageContent.split(/\s+/).length;
      chunk.metadata = { ...chunk.metadata, contentid: contentId };
    });

    const client = createClient(
      apiSettings.supabaseUrl,
      apiSettings.supabaseApiKey
    );
    try {
      await SupabaseVectorStore.fromDocuments(
        output,
        new OpenAIEmbeddings({ openAIApiKey: apiSettings.openAiApiKey }),
        { client, tableName: "docsphere_docus" }
      );
    } catch (error) {
      console.error("Error inserting data into Supabase:", error);
    }

    return tokenCount;
  };


  const moveFiles = async (fileIds, targetFolderId) => {
    try {
      await axios.post(
        `${Helpers.apiUrl}files/move`,
        {
          file_ids: fileIds,
          folder_id: targetFolderId
        },
        {
          headers: Helpers.authHeaders.headers
        }
      );

      getFiles();
      Helpers.toast("success", "Files moved successfully!");
      return true;
    } catch (error) {
      console.error("Error moving files:", error);
      Helpers.toast("error", "Failed to move files.");
      return false;
    }
  };

  const renameFile = async (fileId, newName) => {
    try {
       await axios.put(
        `${Helpers.apiUrl}files/${fileId}/rename`,
        { name: newName },
        {
          headers: Helpers.authHeaders.headers
        }
      );

      getFiles();
      Helpers.toast("success", "File renamed successfully!");
      return true;
    } catch (error) {
      console.error("Error renaming file:", error);
      Helpers.toast("error", "Failed to rename file.");
      return false;
    }
  };

  const deleteFile = async (fileId) => {
    try {
      await axios.delete(
        `${Helpers.apiUrl}files/${fileId}`,
        {
          headers: Helpers.authHeaders.headers
        }
      );

      getFiles();
      Helpers.toast("success", "File deleted successfully!");
      return true;
    } catch (error) {
      console.error("Error deleting file:", error);
      Helpers.toast("error", "Failed to delete file.");
      return false;
    }
  };

  const bulkDeleteFiles = async (fileIds) => {
    try {
      await axios.post(
        `${Helpers.apiUrl}files/bulk-delete`,
        { ids: fileIds },
        {
          headers: Helpers.authHeaders.headers,
        }
      );

      getFiles();
      Helpers.toast("success", "Files deleted successfully!");
      return true;
    } catch (error) {
      console.error("Error deleting files:", error);
      Helpers.toast("error", "Failed to delete files.");
      return false;
    }
  };

  useEffect(() => {
    getFiles();
  }, []);

  return (
    <FileContext.Provider
      value={{
        getFiles,
        files,
        setFiles,
        uploadFiles,
        moveFiles,
        renameFile,
        deleteFile,
        bulkDeleteFiles,
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

export const useFiles = () => {
  const context = useContext(FileContext);
  if (context === undefined) {
    throw new Error("useFiles must be used within a FileProvider");
  }
  return context;
};
