import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { X } from "lucide-react";
import PageLoader from "./Loader/PageLoader";
import { useNavigate } from "react-router-dom";
import { ChatAppContext } from "../Context/AppContext";
import Helpers from "../Config/Helpers";
import { RecursiveCharacterTextSplitter } from "langchain/text_splitter";
import { createClient } from "@supabase/supabase-js";
import { SupabaseVectorStore } from "@langchain/community/vectorstores/supabase";
import { OpenAIEmbeddings } from "@langchain/openai";
import { usePlan } from "../Context/PlanContext";
import { useApi } from "../Context/ApiContext";
import { useFolders } from "../Context/FolderContext";
import { useFiles } from "../Context/FileContext";

export default function ChatModal() {
  const {
    setSelectedDocs,
    preSelectedFile,
    preSelectedFolder,
    setPreSelectedFolder,
    showChatModal,
    handleModalClose,
  } = useContext(ChatAppContext);
  const { files } = useFiles();
  const [loader, setLoader] = useState(false);
  const [inputDocs, setInputDocs] = useState([]);
  const [fromPreviousFiles, setFromPreviousFiles] = useState(false);

  const [selectedFolderFromModel, setSelectedFolderFromModel] = useState(null);
  const [useCase, setUseCase] = useState("");
  const [useCases, setUseCases] = useState([]);
  const { folders, showFolders, setShowFolders,getFolders } = useFolders();
  const [prompts, setPrompts] = useState([]);
  const { apiSettings } = useApi();
  const { isPlanActive, getProfileInfo, TokensAvailable } = usePlan();
  const [dragging, setDragging] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState("");
  const [customPrompt, setCustomPrompt] = useState("");
  const [useCustomPrompt, setUseCustomPrompt] = useState(false);
  const navigate = useNavigate();
  const [modelFiles, setModelFiles] = useState([]);
  const [planState, setplanState] = useState("");

  useEffect(() => {
    isPlanActive && isPlanActive === "Active"
      ? setplanState("Paid")
      : isPlanActive === "Expired"
      ? setplanState("Expired")
      : setplanState("Free");
  }, [isPlanActive]);
  // useEffect(() => {
  //   if (preSelectedFile) {
  //     setShowFolders(false);
  //     setFromPreviousFiles(true);
  //     setInputDocs([preSelectedFile]);
  //   }
  //   if (preSelectedFolder) {
  //     getFolders();
  //     setFromPreviousFiles(false);
  //     setShowFolders(true);
  //     setSelectedFolderFromModel(preSelectedFolder.id);
  //     const selectedFolder = folders.find(
  //       (folder) => folder.id === preSelectedFolder.id
  //     );
  //     if (selectedFolder?.files) {
  //       setInputDocs(selectedFolder.files);
  //     }
  //   }
  // }, [preSelectedFile, preSelectedFolder, folders, setShowFolders]);

  useEffect(() => {
    if (preSelectedFile) {
      setShowFolders(false);
      setFromPreviousFiles(true);
      setInputDocs((prevDocs) =>
        prevDocs[0]?.id === preSelectedFile.id ? prevDocs : [preSelectedFile]
      );
    }
  
    if (preSelectedFolder) {
      setFromPreviousFiles(false);
      setShowFolders(true);
  
      // Only call `getFolders` if `folders` is empty
      if (!folders || folders.length === 0) {
        getFolders();
      }
  
      setSelectedFolderFromModel((prevFolder) =>
        prevFolder === preSelectedFolder.id ? prevFolder : preSelectedFolder.id
      );
  
      const selectedFolder = folders.find(
        (folder) => folder.id === preSelectedFolder.id
      );
      if (selectedFolder?.files) {
        setInputDocs((prevDocs) =>
          JSON.stringify(prevDocs) === JSON.stringify(selectedFolder.files)
            ? prevDocs
            : selectedFolder.files
        );
      }
    }
  }, [preSelectedFile, preSelectedFolder, folders, getFolders, setShowFolders]);
  

  useEffect(() => {
    async function fetchUseCases() {
      try {
        const response = await axios.get(
          `${Helpers.apiUrl}category/all`,
          Helpers.authHeaders
        );
        const useCases = response.data.map((useCase) => ({
          name: useCase.name,
          prompts: useCase.prompts.map((prompt) => ({
            id: prompt.id,
            text: prompt.prompt,
          })),
        }));
        setUseCases(useCases);
        if (useCases.length > 0) {
          setUseCase(useCases[0].name);
          setPrompts(useCases[0].prompts);
          setSelectedPrompt(useCases[0].prompts[0]);
        }
      } catch (error) {
        console.error("Error fetching use cases:", error);
        Helpers.toast("error", "Unable to fetch use cases.");
      }
    }

    fetchUseCases();
  }, []);

  const handlePromptChange = (e) => {
    const prompt = prompts.find(
      (prompt) => prompt.id === parseInt(e.target.value)
    );
    setSelectedPrompt(prompt);
  };

  const handleUseCaseChange = (e) => {
    const caseName = e.target.value;
    setUseCase(caseName);
    const selected = useCases.find((useCase) => useCase.name === caseName);
    if (selected) {
      setPrompts(selected.prompts);
      setSelectedPrompt(selected.prompts[0]);
    }
  };

  const performVectorization = async (chatData, contentId) => {
    if (!contentId) return;
    const splitter = new RecursiveCharacterTextSplitter({
      chunkSize: 500,
      chunkOverlap: 50,
    });
    const documents = await splitter.createDocuments([chatData]);
    const tokenCount = documents.reduce(
      (count, doc) => count + doc.pageContent.split(/\s+/).length,
      0
    );
    documents.forEach(
      (doc) => (doc.metadata = { ...doc.metadata, contentid: contentId })
    );

    const client = createClient(
      apiSettings.supabaseUrl,
      apiSettings.supabaseApiKey
    );
    await SupabaseVectorStore.fromDocuments(
      documents,
      new OpenAIEmbeddings({ openAIApiKey: apiSettings.openAiApiKey }),
      { client, tableName: "docsphere_docus" }
    );

    return tokenCount;
  };

  const handleUpload = async (uploadedFiles) => {
    if (!TokensAvailable) {
      Helpers.toast("error", "Your Tokens Are Almost Finished");
      return;
    }
  
    if (planState === "Expired") {
      Helpers.toast(
        "error",
        "Your plan is expired, please buy/renew the plan first"
      );
      return;
    }
  
    let chatId = null;
    let vectorTokenCount = 0;
  
    try {
      const contentIds = [];
      const invalidFiles = [];
  
      for (const file of uploadedFiles) {
        try {
          // Check file extension
          const validExtensions = ["pdf", "docx", "jpeg", "jpg", "png"];
          const fileExtension = file.name.split(".").pop().toLowerCase();
  
          if (!validExtensions.includes(fileExtension)) {
            invalidFiles.push(file.name);
            throw new Error(
              `Invalid file extension for file: ${file.name}. Allowed extensions are ${validExtensions.join(
                ", "
              )}.`
            );
          }
  
          const formData = new FormData();
          formData.append("file", file);
          formData.append("instructions", customPrompt);
          formData.append("prompt_id", selectedPrompt?.id);
          formData.append("folderId", selectedFolderFromModel);
  
          if (chatId) {
            formData.append("chatid", chatId);
          }
  
          const response = await axios.post(
            `${Helpers.apiUrl}user/save`,
            formData,
            Helpers.authFileHeaders
          );
  
          if (response.data.status === 422) {
            throw new Error(`Failed to upload file: ${response.data.message}`);
          }
  
          if (!chatId) {
            chatId = response.data.chat_id;
          }
  
          const contentId = response.data.content_id;
          const chatContent = response.data.file_content;
  
          contentIds.push(contentId);
  
          const tokens = await performVectorization(chatContent, contentId);
          vectorTokenCount += tokens;
        } catch (fileError) {
          console.error("Error processing file:", file.name, fileError);
          Helpers.toast("error", `Failed to upload file: ${file.name}`);
          invalidFiles.push(file.name);
          break; // Exit the loop if a file fails
        }
      }
  
      // If any files are invalid, notify the user and stop processing
      if (invalidFiles.length > 0) {
        Helpers.toast(
          "error",
          `Upload failed for the following files: ${invalidFiles.join(", ")}`
        );
        return;
      }
  
      // Process completed successfully
      setModelFiles([]);
      setSelectedDocs(contentIds);
      await getProfileInfo();
  
      Helpers.toast(
        "success",
        "Files uploaded and content vectorized successfully"
      );
  
      return chatId;
    } catch (error) {
      console.error("Error during file upload process:", error);
      Helpers.toast("error", "File upload failed.");
    } finally {
      setLoader(false);
    }
  };
  

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setModelFiles((prevFiles) => [...prevFiles, ...newFiles]);
    e.target.value = "";
  };

  const clearFiles = () => {
    setModelFiles([]);
  };

  const handlePreviousDocsSelect = async (inputDocs) => {
    
    if (!TokensAvailable) {
      Helpers.toast("error", "Your Tokens Are Almost Finished");
      return;
    }

    if (planState === "Expired") {
      Helpers.toast(
        "error",
        "Your plan is expired, please buy/renew the plan first"
      );
      return;
    }
    setLoader(true);
    const fileIds = inputDocs.map((doc) => doc.id);
    const fileNames = inputDocs.map((doc) => doc.name);
    try {

      const chatSessionResponse = await axios.post(
        `${Helpers.apiUrl}user/chatSession`,
        {
          file_ids: fileIds,
          file_names: fileNames,
          instructions: customPrompt,
          prompt_id: selectedPrompt?.id,
          folderId: selectedFolderFromModel,
        },
        Helpers.authHeaders
      );

      if (chatSessionResponse.status === 200) {
        setInputDocs([]);
        handleModalClose();
        if (chatSessionResponse.data?.chat_id) {
          setLoader(false);
          navigate(`/chat/chatbot/${chatSessionResponse.data.chat_id}`);
        }
      }
    } catch (error) {
      console.error("Error during document selection:", error);
      Helpers.toast("error", "Failed to process selected documents.");
      setLoader(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    setModelFiles(droppedFiles);
  };

  const handleFileSelect = (e, doc) => {
    if (e.target.checked) {
      setInputDocs([doc]);
    } else {
      setInputDocs([]);
    }
  };

  const handleFolderChat = async () => {

    if (!TokensAvailable) {
      Helpers.toast("error", "Your Tokens Are Almost Finished");
      return;
    }

    if (planState === "Expired") {
      Helpers.toast(
        "error",
        "Your plan is expired, please buy/renew the plan first"
      );
      return;
    }
    setLoader(true);
    try {
      let uploadedFileIds = [];
      let uploadedFileNames = [];
      let chatId = null;

      if (modelFiles.length > 0) {
        chatId = await handleUpload(modelFiles);
        const response = await axios.get(
          `${Helpers.apiUrl}chat/get/${chatId}`,
          Helpers.authHeaders
        );
        if (response.data.content_ids) {
          uploadedFileIds = response.data.content_ids;
          uploadedFileNames = modelFiles.map((file) => file.name);
        }
      }

      const selectedFolder = folders.find(
        (folder) => folder.id === selectedFolderFromModel
      );
      const folderFiles = selectedFolder?.files || [];

      const allFileIds = [
        ...(folderFiles.map((file) => file.id) || []),
        ...uploadedFileIds,
      ];
      const allFileNames = [
        ...(folderFiles.map((file) => file.name) || []),
        ...uploadedFileNames,
      ];

      if (allFileIds.length === 0) {
        Helpers.toast("error", "No files available for chat");
        setLoader(false);
        return;
      }

      if (chatId) {
        setLoader(false);
        handleModalClose();
        navigate(`/chat/chatbot/${chatId}`);
        return;
      }
      const chatSessionResponse = await axios.post(
        `${Helpers.apiUrl}user/chatSession`,
        {
          file_ids: allFileIds,
          file_names: allFileNames,
          instructions: customPrompt,
          prompt_id: selectedPrompt?.id,
          folderId: selectedFolderFromModel,
        },
        Helpers.authHeaders
      );

      if (
        chatSessionResponse.status === 200 &&
        chatSessionResponse.data?.chat_id
      ) {
        setInputDocs([]);
        handleModalClose();
        setLoader(false);
        navigate(`/chat/chatbot/${chatSessionResponse.data.chat_id}`);
      }
    } catch (error) {
      console.error("Error starting folder chat:", error);
      Helpers.toast("error", "Failed to start chat with folder");
    } finally {
      setLoader(false);
    }
  };

  const isFolderSelectedAndHasFiles =
    selectedFolderFromModel &&
    folders.find((folder) => folder.id === parseInt(selectedFolderFromModel))
      ?.files?.length > 0;

  const isButtonEnabled = modelFiles.length > 0 || isFolderSelectedAndHasFiles;
  return (
    <div
      className={`fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center ${
        showChatModal ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg shadow-lg max-w-4xl w-full mx-4 sm:mx-8">
        <div className="border-b px-6 py-4 flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-800">
            Let's Decode this Document!: ✨
          </h2>
          <button
            onClick={handleModalClose}
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        {loader ? (
          <div className="p-6 flex justify-center items-center h-full">
            <PageLoader />
          </div>
        ) : (
          <div className="p-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <div className="flex flex-col gap-6">
                <label className="flex items-center cursor-pointer gap-4">
                  <input
                    type="checkbox"
                    checked={useCustomPrompt}
                    onChange={() => setUseCustomPrompt(!useCustomPrompt)}
                    className="sr-only peer"
                  />
                  <div className="w-12 h-7 bg-gray-200 rounded-full peer-checked:bg-blue-600 relative transition-colors duration-200">
                    <div
                      className={`absolute top-1 left-1 w-5 h-5 bg-white rounded-full shadow-md transition-transform duration-200 ${
                        useCustomPrompt ? "translate-x-5" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                  <span className="text-sm font-medium text-gray-800">
                    Use Custom Prompt
                  </span>
                </label>

                {/* Select Use Case */}
                <div>
                  <label className="text-sm font-medium text-gray-800">
                    Select Use Case
                  </label>
                  <select
                    className="block w-full border border-gray-300 rounded-md p-2 mt-1 text-sm focus:ring-blue-500 focus:border-blue-500"
                    value={useCase}
                    onChange={handleUseCaseChange}
                  >
                    {useCases.map((useCaseObj, index) => (
                      <option key={index} value={useCaseObj.name}>
                        {useCaseObj.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Prompt Input */}
                <div>
                  {useCustomPrompt ? (
                    <>
                      <label className="text-sm font-medium text-gray-800">
                        Enter Custom Prompt
                      </label>
                      <textarea
                        className="block w-full border border-gray-300 rounded-md p-2 mt-1 text-sm focus:ring-blue-500 focus:border-blue-500"
                        rows={3}
                        value={customPrompt}
                        onChange={(e) => setCustomPrompt(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <label className="text-sm font-medium text-gray-800">
                        Choose Prompt
                      </label>
                      <select
                        className="block w-full border border-gray-300 rounded-md p-2 mt-1 text-sm focus:ring-blue-500 focus:border-blue-500"
                        value={selectedPrompt?.id}
                        onChange={handlePromptChange}
                      >
                        {prompts.map((prompt, index) => (
                          <option key={index} value={prompt.id}>
                            {prompt.text}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </div>

                {/* Chat From Previous Documents Toggle */}
                <label className="flex items-center cursor-pointer gap-4">
                  <input
                    type="checkbox"
                    checked={fromPreviousFiles}
                    onChange={() => {
                      setFromPreviousFiles(!fromPreviousFiles);
                      setModelFiles([]);
                    }}
                    className="sr-only peer"
                  />
                  <div className="w-12 h-7 bg-gray-200 rounded-full peer-checked:bg-blue-600 relative transition-colors duration-200">
                    <div
                      className={`absolute top-1 left-1 w-5 h-5 bg-white rounded-full shadow-md transition-transform duration-200 ${
                        fromPreviousFiles ? "translate-x-5" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                  <span className="text-sm font-medium text-gray-800">
                    Chat from previous documents
                  </span>
                </label>
              </div>

              {/* Right Section */}
              <div className="flex flex-col gap-6">
                {fromPreviousFiles ? (
                  <>
                    <label className="text-sm font-medium text-gray-800">
                      Select Files
                    </label>
                    <ul className="max-h-60 overflow-y-auto border border-gray-300 rounded-md p-2 mt-1">
                      {files?.length > 0 ? (
                        files
                          .filter((file) => file.folder_id === null)
                          .map((doc, index) => (
                            <li
                              key={index}
                              className="flex items-center gap-2 py-1"
                            >
                              <input
                                type="radio"
                                name="fileSelection"
                                checked={
                                  inputDocs.length > 0 &&
                                  inputDocs[0].name === doc.name
                                }
                                onChange={(e) => handleFileSelect(e, doc)}
                              />
                              <span className="text-sm text-gray-800">
                                {doc.name}
                              </span>
                            </li>
                          ))
                      ) : (
                        <p className="text-sm text-gray-500">
                          No uploaded files.
                        </p>
                      )}
                    </ul>
                    <button
                      className={`${
                        inputDocs.length === 0
                          ? "bg-blue-300 cursor-not-allowed"
                          : "bg-blue-600 hover:bg-blue-700"
                      } mt-2 text-white px-4 py-2 rounded-md`}
                      onClick={() => handlePreviousDocsSelect(inputDocs)}
                      disabled={inputDocs.length === 0}
                    >
                      Start Chat with Selected File
                    </button>
                  </>
                ) : (
                  <>
                    {/* Select Folder Toggle */}
                    <label className="flex items-center cursor-pointer gap-4">
                      <input
                        type="checkbox"
                        checked={showFolders}
                        onChange={() => {
                          setShowFolders(!showFolders);
                          if (selectedFolderFromModel) {
                            setSelectedFolderFromModel(null);
                          }
                        }}
                        className="sr-only peer"
                      />
                      <div className="w-12 h-7 bg-gray-200 rounded-full peer-checked:bg-blue-600 relative transition-colors duration-200">
                        <div
                          className={`absolute top-1 left-1 w-5 h-5 bg-white rounded-full shadow-md transition-transform duration-200 ${
                            showFolders ? "translate-x-5" : "translate-x-0"
                          }`}
                        ></div>
                      </div>
                      <span className="text-sm font-medium text-gray-800">
                        Select Folder
                      </span>
                    </label>

                    {showFolders && (
                      <div className="flex flex-col gap-2">
                      <select
                          className="border border-gray-300 rounded-md p-2"
                          value={selectedFolderFromModel || ""}
                          onChange={(e) =>{
                            setPreSelectedFolder(null);
                            setSelectedFolderFromModel(e.target.value);
                          }
                          }
                        >
                          <option value="">Select a folder</option>
                          {folders.map((folder, index) => (
                            <option key={index} value={folder.id}>
                              {folder.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    {/* Drag and Drop Files */}
                    <label className="text-sm font-medium text-gray-800">
                      Drag and Drop Files
                    </label>
                    <div
                      className={`border-2 ${
                        dragging
                          ? "border-blue-500 bg-blue-50"
                          : "border-gray-300"
                      } rounded-md flex justify-center items-center p-4 mt-1`}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                    >
                      <label className="flex flex-col items-center cursor-pointer">
                        <input
                          type="file"
                          id="fileInput"
                          multiple
                          onChange={handleFileChange}
                          className="hidden"
                        />
                        {modelFiles.length === 0 ? (
                          <p className="text-sm text-gray-500 text-center">
                            Drag and drop your files here or click to upload
                          </p>
                        ) : (
                          <ul className="list-none">
                            {modelFiles.map((file, index) => (
                              <li key={index} className="text-sm text-gray-800">
                                {file.name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </label>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex gap-4 mt-4">
                      <button
                        className={`${
                          isButtonEnabled
                            ? "bg-blue-600 hover:bg-blue-700"
                            : "bg-blue-300 cursor-not-allowed"
                        } text-white px-4 py-2 rounded-md`}
                        onClick={handleFolderChat}
                        disabled={!isButtonEnabled}
                      >
                        Start Chat
                      </button>
                      {modelFiles.length > 0 && (
                        <button
                          className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md"
                          onClick={clearFiles}
                        >
                          Clear Files
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
