import { useEffect, useState } from "react";
import Helpers from "../../Config/Helpers";
import useTitle from "../../Hooks/useTitle";
import axios from "axios";
import { Link } from "react-router-dom";
import PageLoader from "../../Components/Loader/PageLoader";

const UserDashboard = () => {
  useTitle("Dashboard");
  const [users, setUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [pkgs, setPkgs] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [loader, setLoader] = useState(true);
  const getNestedData = (data) => {
    while (data && typeof data === "object" && "data" in data) {
      data = data.data;
    }
    return data;
  };

  const fetchDashboardData = async () => {
    setLoader(true);
    try {
      const endpoints = {
        users: `${Helpers.apiUrl}user/all`,
        organizations: `${Helpers.apiUrl}organizations/all-organizations`,
        packages: `${Helpers.apiUrl}packages/all-packages`,
        transactions: `${Helpers.apiUrl}transactions/all-transactions`,
        prompts: `${Helpers.apiUrl}prompt/all-prompts`,
        templates: `${Helpers.apiUrl}template/all-templates`,
      };

      const [
        usersRes,
        orgsRes,
        packagesRes,
        transactionsRes,
        promptsRes,
        templatesRes,
      ] = await Promise.all([
        axios.get(endpoints.users, Helpers.authHeaders),
        axios.get(endpoints.organizations, Helpers.authHeaders),
        axios.get(endpoints.packages, Helpers.authHeaders),
        axios.get(endpoints.transactions, Helpers.authHeaders),
        axios.get(endpoints.prompts, Helpers.authHeaders),
        axios.get(endpoints.templates, Helpers.authHeaders),
      ]);

      setUsers(usersRes.data.length);
      setOrgs(orgsRes.data.length);
      setPkgs(packagesRes.data.length);
      setTransactions(transactionsRes.data.length);
      setPrompts(promptsRes.data.length);
      setTemplates(getNestedData(templatesRes.data).length);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      Helpers.toast("error", "Failed to load dashboard data");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <div className="nk-wrap">
      {/* <div className="nk-header nk-header-fixed">
                <div className="container-fluid">
                    <div className="nk-header-wrap">
                        <div className="nk-header-logo ms-n1">
                            <div className="nk-sidebar-toggle me-1">
                                <button className="btn btn-sm btn-zoom btn-icon sidebar-toggle d-sm-none"><em className="icon ni ni-menu"> </em></button>
                                <button className="btn btn-md btn-zoom btn-icon sidebar-toggle d-none d-sm-inline-flex"><em className="icon ni ni-menu"> </em></button>
                            </div>
                            <a href="index-2.html" className="logo-link">
                                <div className="logo-wrap">
                                    <img className="logo-img logo-light" src="images/logo.png" srcSet="https://copygen.themenio.com/dashboard/images/logo2x.png 2x" alt="" />
                                    <img className="logo-img logo-dark" src="images/logo-dark.png" srcSet="https://copygen.themenio.com/dashboard/images/logo-dark2x.png 2x" alt="" />
                                    <img className="logo-img logo-icon" src="images/logo-icon.png" srcSet="https://copygen.themenio.com/dashboard/images/logo-icon2x.png 2x" alt="" />
                                </div>
                            </a>//docsphere.cyberifyportfolio.com
                        </div>
                        <div className="nk-header-tools">
                            <ul className="nk-quick-nav ms-2">
                                <li className="dropdown d-inline-flex">
                                    <a data-bs-toggle="dropdown" className="d-inline-flex" href="#">
                                        <div className="media media-md media-circle media-middle text-bg-primary">
                                        <img src="uploads/default.png" /></div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
      <>
        {loader ? (
          <div className="p-6 flex justify-center items-center h-full">
            <PageLoader />
          </div>
        ) : (
          <div className="nk-content">
            <div className="container-xl">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-page-head dashboard-top">
                    <div className="nk-block-head-between">
                      <div className="nk-block-head-content">
                        <h2 className="display-6">
                          Welcome {Helpers.authUser.name}!
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="row g-gs">
                      <div className="col-sm-6 col-xxl-3">
                        <div className="card  card-full bg-purple bg-opacity-10 border-0">
                          <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="fs-6 text-light mb-0">
                                Total Users
                              </div>
                              <Link
                                to="/admin/users"
                                className="link link-purple"
                              >
                                See All
                              </Link>
                            </div>
                            <h5 className="fs-1">
                              {users}
                              <small className="fs-3"> Users</small>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xxl-3">
                        <div className="card card-full bg-blue bg-opacity-10 border-0">
                          <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                              <div className="fs-6 text-light mb-0">
                                Total Organizations
                              </div>
                              {/* <a href="document-drafts.html" className="link link-blue">See All</a> */}
                            </div>
                            <h5 className="fs-1">
                              {orgs}{" "}
                              <small className="fs-3"> Organizations</small>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xxl-3">
                        <div className="card card-full bg-indigo bg-opacity-10 border-0">
                          <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                              <div className="fs-6 text-light mb-0">
                                Total Packages
                              </div>
                              <Link
                                to="/admin/packages"
                                className="link link-purple"
                              >
                                See All
                              </Link>
                            </div>
                            <h5 className="fs-1">
                              {pkgs} <small className="fs-3">Packages</small>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xxl-3">
                        <div className="card card-full bg-cyan bg-opacity-10 border-0">
                          <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                              <div className="fs-6 text-light mb-0">
                                Total Transactions
                              </div>
                              <Link
                                to="/admin/get-transactions"
                                className="link link-purple"
                              >
                                See All
                              </Link>
                            </div>
                            <h5 className="fs-1">
                              {transactions}{" "}
                              <small className="fs-3">Transactions</small>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block-head">
                    <div className="nk-block-head-between">
                      <div className="nk-block-head-content">
                        <h2 className="display-6">Bundles</h2>
                      </div>
                      {/* <div className="nk-block-head-content"><a href="templates.html" className="link">Explore All</a></div> */}
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="row g-gs">
                      <div className="col-sm-6 col-xxl-3">
                        <div className="card card-full">
                          <div className="card-body">
                            <div className="media media-rg media-middle media-circle text-primary bg-primary bg-opacity-20 mb-3">
                              <em className="icon ni ni-bulb-fill"></em>
                            </div>
                            <h5 className="fs-4 fw-medium">Prompts</h5>
                            <p className="small text-light">
                              Number of Prompts : {prompts}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xxl-3">
                        <div className="card card-full">
                          <div className="card-body">
                            <div className="position-absolute end-0 top-0 me-4 mt-4">
                              <div className="badge text-bg-dark rounded-pill text-uppercase">
                                New
                              </div>
                            </div>
                            <div className="media media-rg media-middle media-circle text-blue bg-blue bg-opacity-20 mb-3">
                              <em className="icon ni ni-spark-fill"></em>
                            </div>
                            <h5 className="fs-4 fw-medium">CV Templates</h5>
                            <p className="small text-light">
                              Number of Templates : {templates}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
      {/* <div className="nk-footer">
                <div className="container-xl">
                    <div className="d-flex align-items-center flex-wrap justify-content-between mx-n3">
                        <div className="nk-footer-links px-3">
                            <ul className="nav nav-sm">
                                <li className="nav-item"><a className="nav-link" href="../index-2.html#">Home</a></li>
                                <li className="nav-item"><a className="nav-link" href="../index-2.html#">Pricing</a></li>
                                <li className="nav-item"><a className="nav-link" href="../index-2.html#">Privacy Policy</a></li>
                                <li className="nav-item"><a className="nav-link" href="../index-2.html#">FAQ</a></li>
                                <li className="nav-item"><a className="nav-link" href="../index-2.html#">Contact</a></li>
                            </ul>
                        </div>
                        <div className="nk-footer-copyright fs-6 px-3">&copy; 2023 All Rights Reserved to <a href="/">docsphere.ai</a>.</div>
                    </div>
                </div>
            </div> */}
    </div>
  );
};

export default UserDashboard;
