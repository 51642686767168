import { useState, useContext } from "react";
import CollectionList from "./CollectionList";
import { MessageSquare, User, Plus, Settings, LogOut } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { KnowledgeBase } from "./KnowledgeBase";
import Helpers from "../Config/Helpers";
import { useNavigate } from "react-router-dom";
import { IoIosRocket } from "react-icons/io";
import { ChatAppContext } from "../Context/AppContext";
import { usePlan } from "../Context/PlanContext";

export function MobileNavbar({ onLogout, handleUpgradePlan }) {
  const navigate = useNavigate();
  const { userData } = usePlan();
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const {
    handleChatModalShow,
    isKnowledgeBaseOpen,
    setIsKnowledgeBaseOpen,
    showCollections,
    setShowCollections,
  } = useContext(ChatAppContext);

  const accountMenuVariants = {
    hidden: { y: "100%" },
    visible: {
      y: 0,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 300,
      },
    },
    exit: {
      y: "100%",
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 300,
      },
    },
  };

  return (
    <div className="relative pb-16">
      <div className="lg:hidden fixed bottom-0 left-0 right-0 bg-white border-t flex justify-around items-center h-16 z-30">
        <button
          className="flex flex-col items-center text-gray-600 hover:text-black"
          onClick={() => setShowCollections(true)}
        >
          <MessageSquare className="h-6 w-6" />
          <span className="text-xs mt-1">Chats</span>
        </button>
        <button
          className="bg-[#1B152B] text-white rounded-full h-14 w-14 flex items-center justify-center -mt-5"
          onClick={() => {
            handleChatModalShow();
          }}
        >
          <Plus className="h-6 w-6" />
        </button>
        <button
          className="flex flex-col items-center text-gray-600 hover:text-black"
          onClick={() => setShowAccountMenu(true)}
        >
          <User className="h-6 w-6" />
          <span className="text-xs mt-1">Account</span>
        </button>
      </div>
      <AnimatePresence>
        {showAccountMenu && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setShowAccountMenu(false)}
              className="fixed inset-0 bg-black bg-opacity-50 z-40"
            />
            <motion.div
              variants={accountMenuVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="fixed bottom-0 left-0 right-0 bg-white rounded-t-2xl z-50"
            >
              <div className="p-6 space-y-6">
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    <div className="w-20 h-20 rounded-full bg-[#6439F5] flex items-center justify-center text-white text-3xl font-semibold">
                      {userData?.name?.charAt(0)?.toUpperCase()}
                    </div>
                    {userData?.profile_pic && (
                      <img
                        className="absolute inset-0 w-20 h-20 rounded-full object-cover"
                        src={Helpers.serverImage(userData?.profile_pic)}
                        alt="Profile"
                      />
                    )}
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900">
                      {Helpers.authUser.name}
                    </h3>
                    <p className="text-sm text-gray-500">
                      {Helpers.authUser.email}
                    </p>
                  </div>
                </div>

                <div className="space-y-4">
                  <button
                    className="flex items-center space-x-3 w-full px-4 py-3 text-gray-700 hover:bg-gray-50 rounded-lg"
                    onClick={() => {
                      navigate("/user/setting");
                    }}
                  >
                    <Settings className="h-5 w-5" />
                    <span>Account Settings</span>
                  </button>
                  <button
                    className="flex items-center space-x-3 w-full px-4 py-3 text-red-600 hover:bg-gray-50 rounded-lg"
                    onClick={() => {
                      onLogout();
                    }}
                  >
                    <LogOut className="h-5 w-5" />
                    <span>Log out</span>
                  </button>
                </div>

                <button
                  className="w-full flex items-center justify-center gap-2 bg-purple-600 text-white py-3 rounded-lg font-medium hover:bg-purple-700"
                  onClick={handleUpgradePlan}
                >
                  <span className="text-lg">
                    <IoIosRocket />
                  </span>
                  <span className="text-[16px] sm:text-[16px]">
                    Upgrade Plan
                  </span>
                </button>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>

      {showCollections && (
        <div className="fixed inset-0 bg-white z-50">
          <div className="flex flex-col h-full m-1">
            <div className="p-4 border-b flex justify-between items-center">
              <h2 className="text-lg font-semibold">History</h2>
              <button
                onClick={() => setShowCollections(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>
            <CollectionList isMobile={true} />
          </div>
        </div>
      )}

      <AnimatePresence>
        {isKnowledgeBaseOpen && (
          <KnowledgeBase
            isOpen={isKnowledgeBaseOpen}
            onClose={() => setIsKnowledgeBaseOpen(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
}
