import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { IoIosRocket } from "react-icons/io";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { BsLayoutSidebar } from "react-icons/bs";
import { TbEaseInOutControlPoints } from "react-icons/tb";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Helpers from "../Config/Helpers";
import logo from "../logo-duo.png";

const Navbar = ({
  disable = false,
  isMobile,
  toggleCollapse,
  planState,
  toggleModelDialog,
  ischat = false,
  onLogout, handleUpgradePlan 
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <>
      <div className="h-[64px] border-b border-[#E8ECEF] mx-2 gap-3 md:pl-4 md:px-[24px] flex flex-row justify-between items-center">
        <div className="d-flex align-items-center gap-3">
          {disable ? null : (
            <div
              onClick={toggleCollapse}
              className="sm:block hidden cursor-pointer hover:bg-[#F3EFFE] p-[8px] rounded-[50%]"
              tooltip="Collapse"
              flow="down"
            >
              <BsLayoutSidebar />
            </div>
          )}
          <a href="/">
            <img
              src={logo}
              alt="Logo"
              style={{
                width: "120px",
                height: "40px",
                padding: "5px",
                borderRadius: "4px",
              }}
            />
          </a>
          <div className="sm:block hidden">
            <p
              className="text-[12px] font-[700] px-[8px] py-[4px] rounded-[4px] bg-[#D6E0FF] text-[#6439F5]"
              style={{ opacity: "1", transform: "none" }}
            >
              {planState}
            </p>
          </div>
        </div>

        <div className="flex flex-row justify-between gap-3 items-center p-1">
          <div className="flex-row gap-2 sm:gap-3 cursor-pointer justify-center items-center flex">
            {(disable || !ischat) ? null : (
              <>
                <div className="items-center justify-center rounded-[50%] sm:rounded-[6px] md:flex flex-row ">
                  <button
                    type="button"
                    onClick={toggleModelDialog}
                    className="flex flex-row items-center border border-[#E8ECEF] rounded-lg py-[10px] px-2 gap-2"
                  >
                    <TbEaseInOutControlPoints />
                    {!isMobile &&(<span className="font-medium text-[14px] ">
                      Model Control
                    </span>)}
                  </button>
                </div>
              </>
            )}
            <div className="block md:hidden border border-[#E8ECEF] rounded-lg py-1 px-2 mx-1">
              <button type="button" onClick={toggleDropdown}>
                <HiOutlineDotsVertical />
              </button>
            </div>

            <div className="sm:block hidden">
              <button
                type="button"
                onClick={toggleDropdown}
                className="flex flex-row items-center border border-[#E8ECEF] rounded-lg py-[10px] px-2"
              >
                <span className="truncate max-w-[333px] text-[14px] font-[500]">
                {Helpers.authUser.name}
                </span>
                <span className="ml-2 h-4 w-4">
                  {isDropdownVisible ? (
                    <FaAngleUp className="text-chatBorder" />
                  ) : (
                    <FaAngleDown className="text-chatBorder" />
                  )}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {isDropdownVisible && (
        <div className="fixed overflow-hidden right-6 md:right-10 top-20 z-50 pointer-events-auto min-w-max rounded-[12px] border border-[#E8ECEF] bg-white shadow-md text-popover-foreground p-1">
          <div className="px-2 py-1.5 text-sm font-semibold">
            <div className="flex flex-col gap-1">
              <span className="font-normal text-[16px] max-w-[300px] truncate w-full">
                {Helpers.authUser.name}
              </span>
              <span className="text-[#8D8A95] text-[14px] font-normal max-w-[300px] truncate w-full">
                {Helpers.authUser.email}
              </span>
            </div>
          </div>

          <div
            role="separator"
            aria-orientation="horizontal"
            className="-mx-1 my-1 h-px bg-muted"
          ></div>
          {disable ? null : (
            <Link
              to="/user/setting"
              className="flex flex-row items-center justify-start gap-3 w-full px-[12px] py-[10px] rounded-[6px] hover:bg-[#f1f2f6]"
            >
              <span className="text-lg">
                <FiSettings />
              </span>
              <span className="text-[16px]">Account Settings</span>
            </Link>
          )}
          <button
            type="button"
            onClick={() => {
              onLogout();
              setIsDropdownVisible(false);
            }}
            className="flex justify-start items-center gap-3 w-full py-2 px-3 sm:px-[12px] sm:py-[10px] rounded-[6px] hover:bg-[#FFE7E6]"
          >
            <span className="text-lg">
              <FiLogOut />
            </span>
            <span className="font-medium sm:font-normal sm:text-[16px] text-[#C02D23] text-[14px]">
              Log out
            </span>
          </button>

          {disable ? null : (
            <div className="mt-[12px]">
              <button
                onClick={handleUpgradePlan}
                className="w-full flex flex-row items-center gap-4 p-[14px] justify-start text-[#ffffff] rounded-xl backgroundgradient bg-[#502EC4] hover:bg-[#3f25a0] transition-colors"
              >
                <span className="text-lg">
                  <IoIosRocket />
                </span>
                <span className="text-[16px] sm:text-[16px]">Upgrade Plan</span>
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Navbar;
