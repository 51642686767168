import React, { useState } from 'react';
import { IoMdClose } from "react-icons/io";
const ModelSelectionDialog = ({ isOpen, onClose, models = [], onSubmit,model }) => {
  const [selectedModel, setSelectedModel] = useState(model);

  if (!isOpen) return null;

  const handleModelSelect = (model) => {
    setSelectedModel(model);
  };

  return (
    <>
      <div
        className="fixed inset-0 z-50 bg-black/50"
        onClick={onClose}
      />

      <div className="fixed left-[50%] top-[50%] z-50 translate-x-[-50%] translate-y-[-50%] rounded-lg bg-white shadow-lg">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-lg font-semibold">Select Model</h2>
          <button
            onClick={onClose}
            className="p-1 rounded-full hover:bg-gray-100"
          >
            <IoMdClose className="h-5 w-5" />
          </button>
        </div>

        {/* Models List */}
        <div className="max-h-[400px] overflow-y-auto p-4">
          <select
            value={selectedModel}
            onChange={(e) => handleModelSelect(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#6439F5] focus:border-transparent"
          >
            <option value="">Select a model</option>
            {models.map((model) => (
              <option key={model.id} value={model.value}>
                {model.name}
              </option>
            ))}
          </select>
        </div>

        {/* Footer */}
        <div className="border-t p-4">
          <div className="flex gap-3">
            <button
              onClick={() => onSubmit(selectedModel)}
              className="px-6 py-2 rounded-lg bg-[#6439F5] text-white hover:bg-[#5331d4]"
            >
              Apply
            </button>
            <button
              onClick={() => {
                setSelectedModel(null);
                onClose();
              }}
              className="px-6 py-2 text-gray-500 hover:text-gray-700"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModelSelectionDialog;