import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Helpers from '../../Config/Helpers.js';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
  const [openAiApiKey, setOpenAiApiKey] = useState('');
  const [model, setModel] = useState('');
  const [models, setModels] = useState([]); 
  const [supabaseApiKey, setSupabaseApiKey] = useState('');
  const [supabaseUrl, setSupabaseUrl] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoadingOpenAi, setIsLoadingOpenAi] = useState(false);
  const [isLoadingSupabase, setIsLoadingSupabase] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const endpoints = {
          settings: `${Helpers.apiUrl}admin/settings`,
          models: `${Helpers.apiUrl}admin/gpt-models`
        };

        const [settingsRes, modelsRes] = await Promise.all([
          axios.get(endpoints.settings, Helpers.authHeaders),
          axios.get(endpoints.models, Helpers.authHeaders)
        ]);

        // Destructure settings data with default values
        const {
          openai_api_key = '',
          model = '',
          supabase_api_key = '',
          supabase_url = ''
        } = settingsRes.data;

        // Update all states at once
        setOpenAiApiKey(openai_api_key);
        setModel(model);
        setSupabaseApiKey(supabase_api_key);
        setSupabaseUrl(supabase_url);
        setModels(modelsRes.data);

      } catch (error) {
        console.error('Failed to fetch settings:', error);
        Helpers.toast(
          'error', 
          'Failed to load settings. Please try again.'
        );
      }
    };

    fetchSettings();
  }, []);

  const handleSubmitOpenAi = async (event) => {
    event.preventDefault();
    setIsLoadingOpenAi(true);

    try {
      await axios.post(`${Helpers.apiUrl}admin/settings/openai`, { api_key: openAiApiKey, model: model }, Helpers.authHeaders);
      Helpers.toast("success", "OpenAI API Key Updated Successfully");
      navigate('/admin/dashboard');
    } catch (error) {
      console.error('Failed to update OpenAI API key', error);
      setErrors({ openai: 'Failed to update OpenAI API key. Please check your input.' });
    }

    setIsLoadingOpenAi(false);
  };

  const handleSubmitSupabase = async (event) => {
    event.preventDefault();
    setIsLoadingSupabase(true);

    try {
      await axios.post(`${Helpers.apiUrl}admin/settings/supabase`, { api_key: supabaseApiKey, url: supabaseUrl }, Helpers.authHeaders);
      Helpers.toast("success", "Supabase API and URL Updated Successfully");
      navigate('/admin/dashboard');
    } catch (error) {
      console.error('Failed to update Supabase settings', error);
      setErrors({ supabase: 'Failed to update Supabase settings. Please check your input.' });
    }

    setIsLoadingSupabase(false);
  };

  return (
    <div className="container mt-5 p-5 shadow-lg rounded bg-white custom-width">
      <div className="row justify-content-center">

        <div className="col-md-5 shadow-lg py-5 rounded-5 m-3">
        <form onSubmit={handleSubmitOpenAi} className="p-3">
            <h2 className="mb-4 text-center">OpenAI Settings</h2>
            <div className="mb-3">
              <label htmlFor="openAiApiKey" className="form-label">OpenAI API Key:</label>
              <input
                type="text"
                className="form-control"
                id="openAiApiKey"
                value={openAiApiKey}
                onChange={(event) => setOpenAiApiKey(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">ChatGPT Model:</label>
              <select className="form-control" value={model} onChange={(e) => setModel(e.target.value)}>
                <option value="" disabled>Select ChatGPT Model</option>
                {models.map((modelOption) => (
                  <option key={modelOption.id} value={modelOption.value}>
                    {modelOption.name}
                  </option>
                ))}
              </select>
              <small className="text-danger">{errors.model ? errors.model[0] : ''}</small>
            </div>
            <div className="d-grid gap-2">
              <button type="submit" className="btn btn-primary" disabled={isLoadingOpenAi}>
                {isLoadingOpenAi ? 'Saving...' : 'Save OpenAI API Key'}
              </button>
            </div>
          </form>
        </div>

        <div className="col-md-5 shadow-lg py-5 rounded-5 m-3">
          <form onSubmit={handleSubmitSupabase} className="p-3">
            <h2 className="mb-4 text-center">Supabase Settings</h2>
            <div className="mb-3">
              <label htmlFor="supabaseApiKey" className="form-label">Supabase API Key:</label>
              <input
                type="text"
                className="form-control"
                id="supabaseApiKey"
                value={supabaseApiKey}
                onChange={(event) => setSupabaseApiKey(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="supabaseUrl" className="form-label">Supabase URL:</label>
              <input
                type="text"
                className="form-control"
                id="supabaseUrl"
                value={supabaseUrl}
                onChange={(event) => setSupabaseUrl(event.target.value)}
              />
            </div>
            <div className="d-grid gap-2">
              <button type="submit" className="btn btn-primary" disabled={isLoadingSupabase}>
                {isLoadingSupabase ? 'Saving...' : 'Save Supabase Settings'}
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  );
};

export default Settings;
