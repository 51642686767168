import React, { createContext, useState } from 'react';

export const ChatAppContext = createContext();

export const ChatAppProvider = ({ children }) => {
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [showChatModal, setShowChatModal] = useState(false);
    const [preSelectedFile, setPreSelectedFile] = useState(null);
    const [preSelectedFolder, setPreSelectedFolder] = useState(null);
    const [isKnowledgeBaseOpen, setIsKnowledgeBaseOpen] = useState(false);
    const [showCollections, setShowCollections] = useState(false);

    const openChatWithFile = (file) => {
        setIsKnowledgeBaseOpen(false);
        setPreSelectedFile(file);
        setPreSelectedFolder(null);
        setShowChatModal(true);
    };
    const handleChatModalShow = () => { 
        setIsKnowledgeBaseOpen(false);
        setShowChatModal(true);
    };
    const handleModalClose = () => {
        setIsKnowledgeBaseOpen(false);
        setPreSelectedFile(null);
        setPreSelectedFolder(null);
        setShowChatModal(false);
      };
    const openChatWithFolder = (folder) => {
        setIsKnowledgeBaseOpen(false);
        setPreSelectedFolder(folder);
        setPreSelectedFile(null);
        setShowChatModal(true);
    };
    

    return (
        <ChatAppContext.Provider value={{
            selectedDocs,
            setSelectedDocs,
            showChatModal,
            setShowChatModal,
            preSelectedFile,
            preSelectedFolder,
            handleChatModalShow,
            handleModalClose,
            openChatWithFile,
            openChatWithFolder,
            setPreSelectedFile,
            setPreSelectedFolder,
            isKnowledgeBaseOpen,
            setIsKnowledgeBaseOpen,
            showCollections, setShowCollections,
        }}>
            {children}
        </ChatAppContext.Provider>
    );
};