import { useEffect, useRef, useState, useContext, useCallback } from "react";
import { PromptTemplate } from "@langchain/core/prompts";
import {
  useParams,
  useNavigate,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import { ChatOpenAI } from "@langchain/openai";
import ChatGPTFormatter from "../../../Components/ChatgptFormatter";
import { combineDocs } from "../../../Components/combineDocs";
import { useRetriever } from "../../../Components/retriever";
import Helpers from "../../../Config/Helpers";
import { ChatAppContext } from "../../../Context/AppContext";
import MarkdownIt from "markdown-it";
import axios from "axios";
import { usePlan } from "../../../Context/PlanContext";
import { useApi } from "../../../Context/ApiContext";
import Navbar from "../../../Components/Navbar";
import ModelSelectionDialog from "../../../Components/ModelSelectionDialog";
import { FiFolder, FiFile } from "react-icons/fi";
import { useFolders } from "../../../Context/FolderContext";
import PageLoader from "../../../Components/Loader/PageLoader";
import { useChatsContext } from "../../../Context/ChatsContext";

const md = new MarkdownIt();

const ChatHeader = ({ chat, folders }) => {
  const folder = folders?.find((f) => f.id === chat?.folder_id);

  return (
    <div className="w-full">
      <div className="flex justify-between items-center h-full px-4 py-2 sm:px-6">
        <div className="flex items-center gap-2 w-full">
          {chat?.folder_id ? (
            <>
              <FiFolder className="h-5 w-5 text-gray-500 flex-shrink-0" />
              <p className="text-sm sm:text-base font-medium truncate">
                {folder?.name}
              </p>
            </>
          ) : (
            <>
              <FiFile className="h-5 w-5 text-gray-500 flex-shrink-0" />
              <p className="text-sm sm:text-base font-medium truncate">
                {chat?.title}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Chatbot = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { apiSettings } = useApi();
  const [planState, setplanState] = useState("");
  const { isCollapsed, setIsCollapsed, logout, handleUpgradePlan, isMobile } =
    useOutletContext();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {
    isPlanActive,
    userData,
    availableTokens,
    TokensAvailable,
    getProfileInfo,
  } = usePlan();
  useEffect(() => {
    isPlanActive && isPlanActive === "Active"
      ? setplanState("Paid")
      : isPlanActive === "Expired"
      ? setplanState("Expired")
      : setplanState("Free");
  }, [isPlanActive]);

  const { selectedDocs, setSelectedDocs, chatFile } =
    useContext(ChatAppContext);
  const retriever = useRetriever(selectedDocs);

  const [pageLoading, setPageLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstResponse, setIsFirstResponse] = useState(false);
  const [noPermission, setNoPermission] = useState(false);
  const [isWriting, setisWriting] = useState(false);
  const { chat_id } = useParams();
  const chatContainer = useRef(null);
  const [questions, setQuestions] = useState([]);

  const { getPreviousChats } = useChatsContext();
  const [modelList, setModelList] = useState([]);
  const [selectedModel, setSelectedModel] = useState(
    apiSettings.openAiApiModel || "gpt-4o"
  );

  const [currentChat, setCurrentChat] = useState(null);
  const { folders } = useFolders();

  useEffect(() => {
    const fetchChatDetails = async () => {
      try {
        const { data } = await axios.get(
          `${Helpers.apiUrl}chat/get/${chat_id}`,
          Helpers.authHeaders
        );
        setCurrentChat(data.chat);
      } catch (error) {
        console.error("Error fetching chat details:", error);
      }
    };

    if (chat_id) {
      fetchChatDetails();
      getPreviousChats();
    }
  }, [chat_id]);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const { data } = await axios.get(
          `${Helpers.apiUrl}admin/gpt-models`,
          Helpers.authHeaders
        );
        setModelList(data);
        if (data.length > 0 && !selectedModel) {
          setSelectedModel(data[0].id);
        }
      } catch (error) {
        Helpers.toast("error", "Failed to fetch models.");
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, []);

  const scrollToBottom = () => {
    if (chatContainer.current) {
      chatContainer.current.scrollTo({
        top: chatContainer.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const isHistory = location.state ? location.state.isHistory : null;

  const downloadSingleMessagePDF = () => {
    navigate(`/user/resume/${chat_id}`);
  };

  const handleSuggestionClick = (suggestion) => {
    if (planState === "Expired") {
      Helpers.toast(
        "error",
        "Your plan is expired, please buy/renew the plan first"
      );
      return;
    } else if (Helpers.authUser.permissions === 1) {
      Helpers.toast("error", "Only Read Permissions");
      return;
    } else {
      getResponse(new Event("submit"), suggestion);
    }
  };

  const handleDownload = async (msg) => {
    try {
      const response = await axios.post(
        `${Helpers.apiUrl}user/generateDocx`,
        { msg: msg },
        Helpers.authHeaders
      );
      const downloadElement = document.createElement("a");
      downloadElement.href = `${Helpers.basePath}/${response.data.filePath}`;
      downloadElement.download = "generated_doc.docx";
      downloadElement.target = "_blank";
      document.body.appendChild(downloadElement);
      downloadElement.click();
      setTimeout(() => {
        delfile(response.data.filePath);
        document.body.removeChild(downloadElement);
      }, 2000);
    } catch (error) {
      Helpers.toast("error", "Failed to generate DOCX.");
      console.error("Error generating DOCX:", error);
    }
  };

  const delfile = async (chatFile) => {
    try {
      await axios.post(
        `${Helpers.apiUrl}user/deletefile`,
        { file: chatFile },
        Helpers.authHeaders
      );
    } catch (error) {
      Helpers.toast("error", "Failed to delete file.");
      console.error("Error deleting file:", error);
    }
  };
  const handleData = async (chatId, msg, is_bot = 0) => {
    await axios
      .post(
        `${Helpers.apiUrl}user/saveMessage/${chatId ? chatId : ""}`,
        {
          message: msg,
          is_bot: is_bot,
        },
        Helpers.authHeaders
      );
  };

  const getChat = useCallback(async () => {
    if (chat_id !== undefined) {
      setPageLoading(true);
      try {
        const { data } = await axios.get(
          `${Helpers.apiUrl}chat/get/${chat_id}`,
          Helpers.authHeaders
        );
        await getProfileInfo();
        if (data.content_ids) {
          setSelectedDocs(data.content_ids);
        } else {
          setSelectedDocs([]);
        }
        setPageLoading(false);
        if (data.chat.messages.length === 0) {
          setMessages([]);
          Helpers.toast("success", "Let's get started with your interaction");
        } else {
          const lastMessage = data.chat.messages[data.chat.messages.length - 1];
          if (
            data.chat.messages.length === 1 &&
            (lastMessage.suggestions == null ||
              lastMessage.suggestions.length === 0)
          ) {
            suggestionQuestions();
          }
          setMessages(data.chat.messages);
          if (
            lastMessage &&
            Array.isArray(lastMessage.suggestions) &&
            lastMessage.suggestions.length > 0
          ) {
            setQuestions(lastMessage.suggestions);
          }
          scrollToBottom();
        }
      } catch (error) {
        setPageLoading(false);
        Helpers.toast("error", "An error occurred while fetching the chat.");
        console.error("Error fetching chat:", error);
      }
    }
  }, [chat_id]);

  const getResponse = async (e, inputText = null) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
  
    if (!TokensAvailable) {
      Helpers.toast("error", "Your Tokens Are Almost Finished");
      return;
    }
  
    if (!apiSettings.openAiApiKey || !selectedModel) {
      Helpers.toast("error", "Your API key or model is missing");
      return;
    }
  
    const message = inputText || userInput;
    setUserInput(""); // Clear the input box
  
    if (!message.trim()) {
      Helpers.toast("error", "Please enter a message.");
      return;
    }
  
    if (!retriever) {
      Helpers.toast("error", "Retriever is not initialized yet");
      return;
    }
  
    setQuestions([]); // Clear any existing suggestions
    setisWriting(true); // Start the writing effect
  
    // Add user's message to chat
    const userMessage = {
      is_bot: 0,
      message: message,
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
  
    // Add a placeholder for assistant's response
    const assistantPlaceholder = { is_bot: 1, message: "" };
    setMessages((prevMessages) => [...prevMessages, assistantPlaceholder]);
  
    const chatHistory = (Array.isArray(messages) ? messages : []).map((msg) => ({
      role: msg.is_bot ? "assistant" : "user",
      content: msg.message,
    }));
    await handleData(chat_id, message); // Save user's message
    chatHistory.push({ role: "user", content: message });
  
    let assistantResponse = ""; // Accumulate assistant's response
    let tokenUsage = { promptTokens: 0, completionTokens: 0, totalTokens: 0 };
  
    try {
      const llm = new ChatOpenAI({
        openAIApiKey: apiSettings.openAiApiKey,
        modelName: selectedModel,
        streaming: true,
        callbacks: [
          {
            handleLLMNewToken: (token) => {
              assistantResponse += token; // Accumulate the response token by token
            
              setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages];
                const assistantIndex = updatedMessages.findLastIndex(
                  (msg) => parseInt(msg.is_bot) === 1
                ); // Find the last message where is_bot === 1
            
                if (assistantIndex >= 0) {
                  // Update the message if is_bot === 1
                  updatedMessages[assistantIndex] = {
                    ...updatedMessages[assistantIndex],
                    message: updatedMessages[assistantIndex].message + token, // Append the token
                  };
                }
                return updatedMessages; // Return the updated messages array
              });
            },
            handleLLMEnd: (output) => {
              const metadata = output?.llmOutput?.estimatedTokenUsage;
              if (metadata) {
                tokenUsage = {
                  promptTokens: metadata.promptTokens || 0,
                  completionTokens: metadata.completionTokens || 0,
                  totalTokens: metadata.totalTokens || 0,
                };
              }
            },
          },
        ],
      });
  
      const standAloneTemplate = `Given a question, convert the question to a standalone question. 
        Question: {question}
        standalone question:`;
      const standAlonePrompt = PromptTemplate.fromTemplate(standAloneTemplate);
  
      const answerTemplate = `You are a helpful and enthusiastic support bot who can answer a given question based on the context provided. If you don't know the answer, say "I'm sorry, I don't know the answer to that." Always speak as if you were chatting to a friend. Use **bold** for important points, *italic* for emphasis, and include new lines using '\\n'. 
        context: {context}
        question: {question}
        answer:
        `;
      const answerPrompt = PromptTemplate.fromTemplate(answerTemplate);
  
      // Generate standalone question
      const standaloneQuestion = await standAlonePrompt.format({
        question: message,
      });
      const docs = await retriever.getRelevantDocuments(standaloneQuestion);
      if (!docs || docs.length === 0) {
        Helpers.toast("info", "No relevant documents found.");
        setisWriting(false);
        return;
      }
  
      const context = combineDocs(docs);
  
      const finalPrompt = await answerPrompt.format({
        context,
        question: message,
      });
  
      await llm.invoke([
        ...chatHistory,
        { role: "system", content: finalPrompt },
      ]);
      await handleData(chat_id, assistantResponse, 1);

      if (tokenUsage.totalTokens > 0) {
        await updateTokens(tokenUsage.totalTokens);
      } else {
        console.warn("No token usage reported.");
      }
  
      await suggestionQuestions();
  
      setisWriting(false);
    } catch (error) {
      Helpers.toast("error", "Failed to process the response.");
      console.error("Error in getResponse:", error);
      setisWriting(false);
    }
  };  

  const updateTokens = async (totalTokensUsed) => {
    try {
      await axios.post(
        `${Helpers.apiUrl}updateTokens`,
        {
          tokens: totalTokensUsed,
        },
        Helpers.authHeaders
      ).then( async () => {
      await getProfileInfo();
      });
    } catch (error) {
      Helpers.toast("error", "Failed to update tokens.");
      console.error("Error updating tokens:", error);
    }
  };

  useEffect(() => {
    if (userData) {
      if (userData.permissions === 1) {
        setNoPermission(true);
      } else {
        setNoPermission(false);
      }
    }
  }, [userData]);

  const suggestionQuestions = async () => {
    try {
      setQuestions([]);
      setisWriting(true);
      scrollToBottom();
      const formData = new FormData();
      formData.append("chatid", chat_id);
      formData.append("model", selectedModel);

      const { data } = await axios.post(
        `${Helpers.apiUrl}user/suggestionQuestions`,
        formData,
        Helpers.authHeaders
      );
      await getProfileInfo();
      setQuestions(data.questions);
      setisWriting(false);
      scrollToBottom();
    } catch (error) {
      Helpers.toast("error", "Failed to get suggestions");
      console.error("Error in getting suggestions:", error);
      setisWriting(false);
    }
  };

  useEffect(() => {
    chat_id == null || chat_id === undefined
      ? navigate("/chat/chat-interface")
      : getChat();
  }, [chat_id, navigate, getChat]);
  useEffect(() => {
    if (!pageLoading && messages.length > 0) {
      scrollToBottom();
    }
  }, [messages, pageLoading]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };
  return (
    <>
      <Navbar
        handleUpgradePlan={handleUpgradePlan}
        isCollapsed={isCollapsed}
        toggleCollapse={toggleCollapse}
        planState={planState}
        onLogout={logout}
        toggleModelDialog={toggleDialog}
        ischat={true}
        isMobile={isMobile}
      />
      <div
        className="p-2"
        style={{
          minHeight: isMobile ? "calc(100vh - 110px)" : "calc(100vh - 50px)",
          overflow: "hidden",
        }}
      >
        <div className="h-100 p-0">
          <div className="h-100">
            {pageLoading ? (
              <PageLoader />
            ) : (
              <div
                className={`h-100  flex flex-column ${isMobile ? "pb-16" : ""}`}
              >
                <ChatHeader chat={currentChat} folders={folders} />
                <div
                  className="flex-grow-1 scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1] scrollbar-thin overflow-auto p-2 sm:p-0"
                  ref={chatContainer}
                >
                  {isFirstResponse && !isHistory ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "24px",
                        color: "#333",
                        borderRadius: "10px",
                        backgroundColor: "#ffffff",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                        textAlign: "center",
                        height: "100%",
                      }}
                    >
                      Your file has been uploaded successfully. You can now
                      enter your prompt.
                    </div>
                  ) : (
                    messages.map((msg, index) => {
                      const isLastMessage = index === messages.length - 1;
                      return (
                        <div
                          key={index}
                          className={`p-4 rounded-lg mb-4 ${
                            parseInt(msg.is_bot) === 0
                              ? "bg-white"
                              : "bg-gray-100"
                          }`}
                        >
                          <div className="">
                            <div className="">
                              <div className="align-center">
                                <div
                                  className={`chat-header ${
                                    parseInt(msg.is_bot) === 1
                                      ? ""
                                      : "flex-row-reverse gap-2 px-2"
                                  }`}
                                >
                                  {parseInt(msg.is_bot) === 0 && (
                                    <div>
                                      <img
                                        className="w-8 h-8 rounded-full object-cover"
                                        src={Helpers.serverImage(
                                          Helpers.authUser.profile_pic
                                        )}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                  {parseInt(msg.is_bot) === 1 && (
                                    <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center">
                                      <img
                                        src="/app/favicon.png"
                                        alt=""
                                        className="w-6 h-6"
                                      />
                                    </div>
                                  )}
                                  <span
                                    className={`chat-user flex ${
                                      parseInt(msg.is_bot) === 0
                                        ? "justify-end"
                                        : " justify-between"
                                    }`}
                                  >
                                    <strong>
                                      {parseInt(msg.is_bot) === 1
                                        ? "DocSphere.AI"
                                        : "You"}
                                    </strong>
                                    {parseInt(msg.is_bot) === 1 && (
                                      <div
                                        className="token-info"
                                        style={{ margin: "10px" }}
                                      >
                                        <p>
                                          Total Tokens:{" "}
                                          {userData?.org_id
                                            ? userData.organization.no_tokens
                                            : userData.no_tokens}
                                        </p>
                                        <p>
                                          Available Tokens:{" "}
                                          {Math.max(availableTokens, 0)}
                                        </p>
                                      </div>
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="chat-divider"></div>
                            </div>
                            <div className="">
                              <div
                                className={`message ${
                                  parseInt(msg.is_bot) === 1
                                    ? ""
                                    : "text-end !pb-2 pt-4"
                                }`}
                              >
                                <ChatGPTFormatter
                                  response={msg.message}
                                  writing={
                                    messages.length - 1 === index && isWriting
                                  }
                                />
                              </div>
                              {parseInt(msg.is_bot) === 1 &&
                                isLastMessage &&
                                Array.isArray(questions) &&
                                questions.length > 0 && (
                                  <div className="pb-4 flex flex-wrap justify-center gap-2">
                                    {questions.map((suggestion, index) => (
                                      <div
                                        key={index}
                                        className="card suggestion-card border transition !duration-400 border-gray-500 suggestions-card hover:!border-gray-900 cursor-pointer"
                                        onClick={() =>
                                          handleSuggestionClick(
                                            suggestion.trim()
                                          )
                                        }
                                      >
                                        <p className="text-sm suggestions-text text-black">
                                          {suggestion.trim()}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              {parseInt(msg.is_bot) === 1 && (
                                <div className="flex justify-center">
                                  <button
                                    style={{
                                      color: "#d308bd",
                                      border: "1px solid #d308bd",
                                      borderRadius: "20px",
                                    }}
                                    className="px-4 py-2  mx-1"
                                    onClick={() =>
                                      downloadSingleMessagePDF(msg.message)
                                    }
                                  >
                                    Download PDF
                                  </button>
                                  <button
                                    className="btn"
                                    style={{
                                      color: "#d308bd",
                                      border: "1px solid #d308bd",
                                      borderRadius: "20px",
                                    }}
                                    onClick={() =>
                                      handleDownload(md.render(msg.message))
                                    }
                                  >
                                    Download DOCX
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
                <div
                  className="mb-4 md:mx-2 px-2 py-1 special-margin rounded"
                  style={{
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    paddingBottom: isMobile ? "110px" : "50px",
                  }}
                >
                  <form
                    className="mx-1"
                    onSubmit={(e) => {
                      e.preventDefault();
                      getResponse(e);
                    }}
                  >
                    <div className="flex items-center flex-wrap">
                      <div className="flex-grow-1">
                        <input
                          className="w-full h-[44px] md:p-2 rounded-lg"
                          value={userInput}
                          onChange={(e) => setUserInput(e.target.value)}
                          placeholder={
                            planState === "Expired"
                              ? "You cannot use the bot, please buy/renew the plan first"
                              : noPermission
                              ? "You don't have write permission."
                              : "Enter a prompt here"
                          }
                          disabled={noPermission || planState === "Expired"}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                              e.preventDefault();
                              getResponse(e);
                            }
                          }}
                        />
                      </div>
                      <div className="ml-2">
                        <button
                          type="submit"
                          className="btn btn-md w-100"
                          style={{
                            backgroundColor: "#671BB3",
                            color: "#ffffff",
                            borderRadius: "20px",
                          }}
                          disabled={
                            isLoading || planState === "Expired" || noPermission
                          }
                        >
                          <span className="mx-2">Send</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isDialogOpen && (
        <ModelSelectionDialog
          model={selectedModel}
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          models={modelList}
          onSubmit={(model) => {
            setSelectedModel(model);
            setIsDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Chatbot;
