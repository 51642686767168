import React, { useState, useEffect, useMemo, useContext } from "react";
import { motion } from "framer-motion";
import { ChevronDownIcon, ChevronRightIcon, X, Plus } from "lucide-react";
import {
  FiFolder,
  FiMoreVertical,
  FiFile,
  FiEdit2,
  FiTrash2,
  FiMove,
  FiPlay,
} from "react-icons/fi";
import LayoutLoader from "./Loader/LayoutLoader";
import Helpers from "../Config/Helpers";
import { useFolders } from "../Context/FolderContext";
import { useFiles } from "../Context/FileContext";
import { ChatAppContext } from "../Context/AppContext";
import { usePlan } from "../Context/PlanContext";

export function KnowledgeBase({ isOpen, onClose }) {
  const [activeTab, setActiveTab] = useState("collections");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [showMenu, setShowMenu] = useState({ type: null, id: null });
  const [fileuploading, setFileUploading] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState({
    show: false,
    type: null,
    id: null,
  });
  const [showRenameModal, setShowRenameModal] = useState({
    show: false,
    type: null,
    id: null,
    currentName: "",
  });
  const [newName, setNewName] = useState("");
  const [showCreateModal, setShowCreateModal] = useState({
    show: false,
    type: null,
  });
  const [newCollectionName, setNewCollectionName] = useState("");
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [showMoveToCollectionModal, setShowMoveToCollectionModal] =
    useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const {
    folders,
    createFolder,
    deleteFolder,
    renameFolder,
    bulkDeleteFolders,
    getFolders,
  } = useFolders();
  const {
    files,
    uploadFiles,
    moveFiles,
    renameFile,
    deleteFile,
    bulkDeleteFiles,
    getFiles,
  } = useFiles() || {};
  const [collections, setCollections] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [currentCollectionForUpload, setCurrentCollectionForUpload] =
    useState(null);
  const [expandedCollections, setExpandedCollections] = useState({});
  const { openChatWithFile, openChatWithFolder } = useContext(ChatAppContext);
  const { isPlanActive } = usePlan();
  const [planState, setplanState] = useState("");
  const [dragging, setDragging] = useState(false);
  const [modelFiles, setModelFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    isPlanActive && isPlanActive === "Active"
      ? setplanState("Paid")
      : isPlanActive === "Expired"
      ? setplanState("Expired")
      : setplanState("Free");
  }, [isPlanActive]);
  const handleFilePlay = (file) => {
    openChatWithFile(file);
  };

  const handleFolderPlay = (folder) => {
    openChatWithFolder(folder);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    setModelFiles(droppedFiles); // Replace old files with new drop
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setModelFiles((prevFiles) => [...prevFiles, ...newFiles]);
    e.target.value = "";
  };
  const filteredCollections = useMemo(() => {
    if (!searchQuery.trim()) return collections ?? []; // Default to an empty array
    return collections.filter((collection) =>
      collection.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [collections, searchQuery]);

  const filteredDocuments = useMemo(() => {
    if (!searchQuery.trim()) return documents ?? []; // Default to an empty array
    return documents.filter((doc) =>
      doc.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [documents, searchQuery]);

  const handleMenuToggle = (type, id) => {
    if (showMenu.type === type && showMenu.id === id) {
      setShowMenu({ type: null, id: null });
    } else {
      setShowMenu({ type, id });
    }
  };

  // useEffect(() => {
  //   setCollections(folders || []);
  // }, [folders]);

  useEffect(() => {
    if (folders && files) {
      const updatedCollections = folders.map((folder) => ({
        ...folder,
        files: files.filter((file) => file.folder_id === folder.id),
      }));
      setCollections(updatedCollections);
    } else {
      setCollections([]);
    }
  }, [folders, files]);

  useEffect(() => {
    setDocuments(files?.filter((file) => file.folder_id === null) || []);
  }, [files]);

  const handleDeleteConfirm = async () => {
    const { type, id } = showConfirmDelete;

    try {
      let success = false;
      if (type === "collection") success = await deleteFolder(id);
      if (type === "document") success = await deleteFile(id);
      if (type === "bulk") {
        success =
          activeTab === "collections"
            ? await bulkDeleteFolders(selectedItems)
            : await bulkDeleteFiles(selectedItems);
      }

      if (success) {
        setShowConfirmDelete({ show: false, type: null, id: null });
        setShowMenu({ type: null, id: null });
        setSelectedItems([]);
        setSelectAll(false);
      }
    } catch (error) {
      console.error("Error during deletion:", error);
      Helpers.toast("error", "Failed to delete item(s).");
    }
  };

  const handleRename = async () => {
    const { type, id } = showRenameModal;
    const trimmedName = newName.trim();

    const resetState = () => {
      setShowRenameModal({
        show: false,
        type: null,
        id: null,
        currentName: "",
      });
      setNewName("");
      setShowMenu({ type: null, id: null });
    };

    try {
      let success = false;

      if (type === "collection") {
        success = await renameFolder(id, trimmedName);
      } else if (type === "document") {
        success = await renameFile(id, trimmedName);
      }

      if (success) {
        if (type === "collection") {
          setCollections(folders || []);
        } else if (type === "document") {
          setDocuments(files?.filter((file) => file.folder_id === null) || []);
        }
        resetState();
      } else {
        throw new Error("Rename operation failed.");
      }
    } catch (error) {
      console.error(`Error renaming ${type}:`, error);
      Helpers.toast(
        "error",
        `Failed to rename ${type === "collection" ? "collection" : "document"}.`
      );
    }
  };

  const handleBulkAction = (action) => {
    if (selectedItems.length === 0) return;

    if (action === "delete") {
      setShowConfirmDelete({
        show: true,
        type: "bulk",
        title: `Delete Selected ${
          activeTab === "collections" ? "Collections" : "Documents"
        }`,
        message: `Are you sure you want to delete ${
          selectedItems.length
        } selected ${
          activeTab === "collections" ? "collections" : "documents"
        }? This action cannot be undone.`,
      });
    } else if (action === "move") {
      setShowMoveToCollectionModal(true);
    }
  };

  const handleBulkMove = async () => {
    if (!selectedCollection || selectedItems.length === 0) return;

    setLoading(true);
    try {
      const success = await moveFiles(selectedItems, selectedCollection);
      if (success) {
        setSelectedItems([]);
        setSelectAll(false);
        setShowMoveToCollectionModal(false);
        setSelectedCollection(null);
        await getFolders();
        await getFiles();

        setCollections((prevFolders) => {
          return prevFolders || [];
        });

        setDocuments((prevFiles) => {
          return prevFiles || [];
        });
      }
    } catch (error) {
      console.error("Error moving files:", error);
      Helpers.toast("error", "Failed to move files.");
    } finally {
      setLoading(false);
    }
  };

  const DropdownMenu = ({ type, item, onClose }) => (
    <div className="absolute right-0 top-8 bg-white rounded-lg shadow-lg border py-1 min-w-[160px] z-10">
      {type === "collection" ? (
        <div>
          <button
            className="w-full px-3 py-1.5 text-sm text-left text-gray-700 hover:bg-gray-50 flex items-center gap-2"
            onClick={() => {
              setCurrentCollectionForUpload(item.id);
              setShowCreateModal({ show: true, type: "document" });
              onClose();
            }}
          >
            <Plus className="h-4 w-4" />
            Add Document
          </button>
          <button
            className="w-full px-3 py-1.5 text-sm text-left text-gray-700 hover:bg-gray-50 flex items-center gap-2"
            onClick={() => {
              setShowRenameModal({
                show: true,
                type: "collection",
                id: item.id,
                currentName: item.name,
              });
              setNewName(item.name);
              onClose();
            }}
          >
            <FiEdit2 className="h-4 w-4" />
            Rename
          </button>
          <button
            className="w-full px-3 py-1.5 text-sm text-left text-red-600 hover:bg-red-50 flex items-center gap-2"
            onClick={() => {
              setShowConfirmDelete({
                show: true,
                type: "collection",
                id: item.id,
                title: "Delete Collection",
                message:
                  "Are you sure you want to delete this collection? All files within this collection will also be deleted.",
              });
              onClose();
            }}
          >
            <FiTrash2 className="h-4 w-4" />
            Delete
          </button>
        </div>
      ) : (
        <div>
          <button
            className="w-full px-3 py-1.5 text-sm text-left text-gray-700 hover:bg-gray-50 flex items-center gap-2"
            onClick={() => {
              setShowRenameModal({
                show: true,
                type: "document",
                id: item.id,
                currentName: item.name,
              });
              setNewName(item.name);
              onClose();
            }}
          >
            <FiEdit2 className="h-4 w-4" />
            Rename
          </button>
          <button
            className="w-full px-3 py-1.5 text-sm text-left text-gray-700 hover:bg-gray-50 flex items-center gap-2"
            onClick={() => {
              setSelectedItems([item.id]);
              setShowMoveToCollectionModal(true);
              onClose();
            }}
          >
            <FiMove className="h-4 w-4" />
            Move
          </button>
          <button
            className="w-full px-3 py-1.5 text-sm text-left text-red-600 hover:bg-red-50 flex items-center gap-2"
            onClick={() => {
              setShowConfirmDelete({
                show: true,
                type: "document",
                id: item.id,
                title: "Delete Document",
                message: "Are you sure you want to delete this document?",
              });
              onClose();
            }}
          >
            <FiTrash2 className="h-4 w-4" />
            Delete
          </button>
        </div>
      )}
    </div>
  );

  const DeleteConfirmationModal = () => (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
        <h3 className="text-lg font-semibold mb-2">Confirm Delete</h3>
        <p className="text-gray-600 mb-4">
          Are you sure you want to delete this {showConfirmDelete.type}? This
          action cannot be undone.
        </p>
        <div className="flex justify-end gap-2">
          <button
            className="px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded-md"
            onClick={() =>
              setShowConfirmDelete({ show: false, type: null, id: null })
            }
          >
            Cancel
          </button>
          <button
            className="px-3 py-1.5 text-sm text-white bg-red-600 hover:bg-red-700 rounded-md"
            onClick={handleDeleteConfirm}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );

  const handleCreateCollection = async () => {
    if (!newCollectionName.trim()) return;

    const success = await createFolder(newCollectionName.trim());

    if (success) {
      setShowCreateModal({ show: false, type: null });
      setNewCollectionName("");
    }
  };

  const RenameModal = () => (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-4 max-w-sm w-full mx-4">
        <h3 className="text-lg font-semibold mb-2">
          Rename {showRenameModal.type}
        </h3>
        <input
          type="text"
          className="w-full p-2 border rounded-md mb-4"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          placeholder={`Enter new ${showRenameModal.type} name`}
          autoFocus
        />
        <div className="flex justify-end gap-2">
          <button
            className="px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded-md"
            onClick={() => {
              setShowRenameModal({
                show: false,
                type: null,
                id: null,
                currentName: "",
              });
              setNewName("");
            }}
          >
            Cancel
          </button>
          <button
            className="px-3 py-1.5 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded-md"
            onClick={handleRename}
            disabled={!newName.trim()}
          >
            Rename
          </button>
        </div>
      </div>
    </div>
  );

  const CreateCollectionModal = () => (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg md:p-6 p-4 max-w-sm w-full mx-4">
        <h3 className="text-lg font-semibold mb-2">Create New Collection</h3>
        <input
          type="text"
          className="w-full p-2 border rounded-md mb-4"
          value={newCollectionName}
          onChange={(e) => setNewCollectionName(e.target.value)}
          placeholder="Enter collection name"
          autoFocus
        />
        <div className="flex justify-end gap-2">
          <button
            className="px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded-md"
            onClick={() => {
              setShowCreateModal({ show: false, type: null });
              setNewCollectionName("");
            }}
          >
            Cancel
          </button>
          <button
            className="px-3 py-1.5 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded-md"
            onClick={handleCreateCollection}
            disabled={!newCollectionName.trim()}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );

  const handleFileUpload = async () => {
    if (planState === "Expired") {
      Helpers.toast(
        "error",
        "Your plan is expired, please buy/renew the plan first"
      );
      return;
    }

    if (!modelFiles || modelFiles.length === 0) {
      Helpers.toast("error", "Please select files to upload");
      return;
    }

    setFileUploading(true);
    try {
      const folderId =
        currentCollectionForUpload ||
        (activeTab === "collections" ? selectedCollection?.id : null);

      const success = await uploadFiles(modelFiles, folderId);

      if (success) {
        setShowCreateModal({ show: false, type: null });
        setCurrentCollectionForUpload(null);
        setModelFiles([]);
        await getFolders();
        await getFiles();
        setCollections(folders || []);
        setDocuments(files || []);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      Helpers.toast("error", "Failed to upload files");
    } finally {
      setFileUploading(false);
      setModelFiles([]);
    }
  };

  const AddDocumentModal = () => (
    <div className="absolute inset-0 bg-black bg-opacity-30 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg md:p-6 p-4 max-w-sm w-full mx-4">
        <h3 className="text-lg font-semibold mb-2">
          {currentCollectionForUpload
            ? "Add Document to Collection"
            : "Add Document"}
        </h3>
        {fileuploading ? (
          <LayoutLoader />
        ) : (
          <div
            className={`${
              dragging ? "border border-primary bg-light" : ""
            } mb-2`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{
              height: "230px",
              border: "2px dashed lightgrey",
              position: "relative",
            }}
          >
            <label
              className="d-flex justify-content-center align-items-center w-100 h-100"
              htmlFor="fileInput"
              style={{ cursor: "pointer" }}
            >
              <input
                type="file"
                id="fileInput"
                multiple
                onChange={handleFileChange}
                style={{
                  opacity: 0,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                }}
              />
              <div>
                {modelFiles.length === 0 ? (
                  <p className="text-center">
                    Drag and drop your files here or click to upload
                  </p>
                ) : (
                  <ul className="list-unstyled">
                    {modelFiles.map((file, index) => (
                      <li key={index}>{file.name}</li>
                    ))}
                  </ul>
                )}
              </div>
            </label>
          </div>
        )}
        <div className="flex justify-end gap-2">
          <button
            className={`px-3 py-1.5 text-sm text-white  rounded-md ${(!modelFiles.length || fileuploading) ?"bg-purple-400": "bg-purple-600 hover:bg-purple-700"}`}
            onClick={handleFileUpload}
            disabled={!modelFiles.length || fileuploading}
          >
            Upload
          </button>
          <button
            className="px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded-md"
            onClick={() => {
              setModelFiles([]);
              setShowCreateModal({ show: false, type: null });
              setCurrentCollectionForUpload(null);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  const MoveToCollectionModal = () => (
    <div className="absolute inset-0 bg-black bg-opacity-30 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg md:p-6 p-4 max-w-sm w-full mx-4">
        <h3 className="text-lg font-semibold mb-4">Move to Collection</h3>
        <select
          className="w-full p-2 border rounded-md mb-4"
          value={selectedCollection || ""}
          onChange={(e) => setSelectedCollection(e.target.value)}
        >
          <option value="">Select a collection</option>
          {collections.map((collection) => (
            <option key={collection.id} value={collection.id}>
              {collection.name}
            </option>
          ))}
        </select>
        <div className="flex justify-end gap-2">
          <button
            className="px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded-md"
            onClick={() => {
              setShowMoveToCollectionModal(false);
              setSelectedCollection(null);
            }}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded-md"
            onClick={handleBulkMove}
            disabled={!selectedCollection}
          >
            Move
          </button>
        </div>
      </div>
    </div>
  );

  const toggleCollectionExpand = (collectionId) => {
    setExpandedCollections((prev) => ({
      ...prev,
      [collectionId]: !prev[collectionId],
    }));
  };

  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      exit={{ y: "100%" }}
      className="fixed inset-0 bg-white z-50 px-2"
    >
      <div className="flex  justify-between items-center md:p-4 p-2 border-b">
        <h2 className="text-xl font-semibold">Document Vault</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <X className="w-5 h-5" />
        </button>
      </div>

      <div className="flex flex-col justify-between my-2">
        <div className="flex flex-col sm:flex-row gap-3 justify-between items-center">
          <div className="flex gap-3">
            <button
              className={`px-3 py-1 md:py-2 rounded-full ${
                activeTab === "collections"
                  ? "bg-gray-900 text-white"
                  : "bg-gray-100 text-gray-600"
              }`}
              onClick={() => {
                setSelectAll(false);
                setSelectedItems([]);
                setActiveTab("collections");
              }}
            >
              Collections
            </button>
            <button
              className={`px-3  md:py-2 rounded-full ${
                activeTab === "documents"
                  ? "bg-gray-900 text-white"
                  : "bg-gray-100 text-gray-600"
              }`}
              onClick={() => {
                setSelectedItems([]);
                setSelectAll(false);
                setActiveTab("documents");
              }}
            >
              Documents
            </button>
          </div>

          <div className="flex flex-col sm:flex-row gap-1 sm:px-0 px-3 justify-between items-center">
            {selectedItems.length > 0 && (
              <div className="flex items-center gap-2">
                {activeTab === "documents" && (
                  <button
                    className="px-2 py-1.5 text-sm text-gray-700 hover:bg-gray-100 rounded-md flex items-center gap-1"
                    onClick={() => handleBulkAction("move")}
                  >
                    <FiMove className="h-4 w-4" />
                    Move
                  </button>
                )}
                <button
                  className="px-2 py-1.5 text-sm text-red-600 hover:bg-red-50 rounded-md flex items-center gap-1"
                  onClick={() => handleBulkAction("delete")}
                >
                  <FiTrash2 className="h-4 w-4" />
                  Delete
                </button>
              </div>
            )}
            <div className="flex items-center gap-2">
              <input
                type="text"
                placeholder="Search..."
                className="px-3 py-1.5 rounded-lg border border-gray-200 text-sm"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <button
                className="px-3 py-1.5 bg-purple-600 text-white rounded-full flex items-center gap-2 hover:bg-purple-700"
                onClick={() =>
                  setShowCreateModal({
                    show: true,
                    type:
                      activeTab === "collections" ? "collection" : "document",
                  })
                }
              >
                <Plus className="h-4 w-4" />
                <span>{activeTab === "collections" ? "Create" : "Upload"}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col h-[calc(100vh-180px)]">
        <div className="flex-1 overflow-auto">
          {activeTab === "collections" ? (
            loading ? (
              <LayoutLoader />
            ) : (
              <table className="w-full">
                <thead className="bg-gray-50 sticky top-0">
                  <tr>
                    <th className="w-8 p-4">
                      <input
                        type="checkbox"
                        className="rounded border-gray-300"
                        checked={selectAll}
                        onChange={(e) => {
                          setSelectAll(e.target.checked);
                          setSelectedItems(
                            e.target.checked ? collections.map((c) => c.id) : []
                          );
                        }}
                      />
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-gray-600">
                      Name
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-gray-600">
                      Files
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-gray-600">
                      Created on
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-gray-600">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(filteredCollections || []).map((collection) => (
                    <React.Fragment key={collection.id}>
                      {/* Folder Row */}
                      <tr className="border-t">
                        <td className="p-4">
                          <input
                            type="checkbox"
                            className="rounded border-gray-300"
                            checked={selectedItems.includes(collection.id)}
                            onChange={() => {
                              setSelectedItems((prev) =>
                                prev.includes(collection.id)
                                  ? prev.filter((id) => id !== collection.id)
                                  : [...prev, collection.id]
                              );
                            }}
                          />
                        </td>
                        <td className="p-4">
                          <button
                            onClick={() =>
                              toggleCollectionExpand(collection.id)
                            }
                            className="flex items-center gap-2"
                          >
                            {expandedCollections[collection.id] ? (
                              <ChevronDownIcon className="h-3.5 w-3.5" />
                            ) : (
                              <ChevronRightIcon className="h-3.5 w-3.5" />
                            )}
                            <FiFolder className="h-5 w-5 text-yellow-400" />
                            <span className="text-sm font-medium">
                              {collection.name}
                            </span>
                          </button>
                        </td>
                        <td className="p-4 text-sm text-gray-500">
                          {collection.files?.length || 0} files
                        </td>
                        <td className="p-4 text-sm text-gray-500">
                          {new Date(collection.created_at).toLocaleDateString()}
                        </td>
                        <td className="flex items-center gap-2 p-4 text-sm text-gray-500">
                          <button onClick={() => handleFolderPlay(collection)}>
                            <FiPlay className="h-4 w-4" />
                          </button>
                          <div className="relative">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMenuToggle("collection", collection.id);
                              }}
                              className="p-1.5 hover:bg-gray-100 rounded-md"
                            >
                              <FiMoreVertical className="h-4 w-4 text-gray-500" />
                            </button>
                            {showMenu.type === "collection" &&
                              showMenu.id === collection.id && (
                                <DropdownMenu
                                  type="collection"
                                  item={collection}
                                  onClose={() =>
                                    setShowMenu({ type: null, id: null })
                                  }
                                />
                              )}
                          </div>
                        </td>
                      </tr>

                      {/* Files List Row */}
                      {expandedCollections[collection.id] && (
                        <tr>
                          <td colSpan="5" className="border-t">
                            <div className="bg-gray-50 px-32 rounded-md">
                              <table className="w-full">
                                <tbody>
                                  {files
                                    ?.filter(
                                      (file) => file.folder_id === collection.id
                                    )
                                    .map((file) => (
                                      <tr
                                        key={file.id}
                                        className="border-b last:border-b-0 hover:bg-gray-100"
                                      >
                                        <td className="p-4">
                                          <div className="flex items-center gap-2">
                                            <FiFile className="h-4 w-4 text-gray-400" />
                                            <span className="text-sm">
                                              {file.name}
                                            </span>
                                          </div>
                                        </td>
                                        <td className="p-4 text-sm text-gray-500">
                                          {new Date(
                                            file.created_at
                                          ).toLocaleDateString()}
                                        </td>
                                        <td className="p-4">
                                          <div className="relative">
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleMenuToggle(
                                                  "document",
                                                  file.id
                                                );
                                              }}
                                              className="p-1.5 hover:bg-gray-100 rounded-md"
                                            >
                                              <FiMoreVertical className="h-4 w-4 text-gray-500" />
                                            </button>
                                            {showMenu.type === "document" &&
                                              showMenu.id === file.id && (
                                                <DropdownMenu
                                                  type="document"
                                                  item={file}
                                                  onClose={() =>
                                                    setShowMenu({
                                                      type: null,
                                                      id: null,
                                                    })
                                                  }
                                                />
                                              )}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )
          ) : (
            <table className="w-full">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  <th className="w-8 p-4">
                    <input
                      type="checkbox"
                      className="rounded border-gray-300"
                      checked={selectAll}
                      onChange={(e) => {
                        setSelectAll(e.target.checked);
                        setSelectedItems(
                          e.target.checked ? documents.map((d) => d.id) : []
                        );
                      }}
                    />
                  </th>
                  <th className="text-left p-4 text-sm font-medium text-gray-600">
                    Name
                  </th>
                  <th className="text-left p-4 text-sm font-medium text-gray-600">
                    Created On
                  </th>
                  <th className="text-left p-4 text-sm font-medium text-gray-600">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {(filteredDocuments ?? []).map((doc) => (
                  <tr key={doc.id} className="border-t">
                    <td className="p-4">
                      <input
                        type="checkbox"
                        className="rounded border-gray-300"
                        checked={selectedItems.includes(doc.id)}
                        onChange={() => {
                          setSelectedItems((prev) =>
                            prev.includes(doc.id)
                              ? prev.filter((id) => id !== doc.id)
                              : [...prev, doc.id]
                          );
                        }}
                      />
                    </td>
                    <td className="p-4">
                      <div className="flex items-center gap-2">
                        <FiFile className="h-4 w-4 text-gray-400" />
                        <span className="text-sm">{doc.name}</span>
                      </div>
                    </td>
                    <td className="p-4 text-sm text-gray-500">
                      {new Date(doc.created_at).toLocaleDateString()}
                    </td>
                    <td className="flex items-center gap-2 p-4 text-sm text-gray-500">
                      <button onClick={() => handleFilePlay(doc)}>
                        <FiPlay className="h-4 w-4" />
                      </button>
                      <div className="relative">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleMenuToggle("document", doc.id);
                          }}
                          className="p-1.5 hover:bg-gray-100 rounded-md"
                        >
                          <FiMoreVertical className="h-4 w-4 text-gray-500" />
                        </button>
                        {showMenu.type === "document" &&
                          showMenu.id === doc.id && (
                            <DropdownMenu
                              type="document"
                              item={doc}
                              onClose={() =>
                                setShowMenu({ type: null, id: null })
                              }
                            />
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {showRenameModal.show && <RenameModal />}

      {showConfirmDelete.show && <DeleteConfirmationModal />}

      {showCreateModal.show && showCreateModal.type === "collection" && (
        <CreateCollectionModal />
      )}

      {showCreateModal.show && showCreateModal.type === "document" && (
        <AddDocumentModal />
      )}

      {showMoveToCollectionModal && <MoveToCollectionModal />}

      {showConfirmDelete.show && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
            <h3 className="text-lg font-semibold mb-2">
              {showConfirmDelete.title}
            </h3>
            <p className="text-gray-600 mb-4">{showConfirmDelete.message}</p>
            <div className="flex justify-end gap-2">
              <button
                className="px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded-md"
                onClick={() => {
                  setShowConfirmDelete({ show: false, type: null, id: null });
                  setSelectedItems([]);
                }}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-sm text-white bg-red-600 hover:bg-red-700 rounded-md"
                onClick={handleDeleteConfirm}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
}
