import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { MobileNavbar } from "../../../Components/MobileNavbar";
import Helpers from "../../../Config/Helpers";
import ChatModal from "../../../Components/ChatModal";
import axios from "axios";

const Layout = () => {
  
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);  
  const logout = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${Helpers.apiUrl}logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        Helpers.toast("success", response.data.message);
        Helpers.authUser = null;
        localStorage.clear();
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleUpgradePlan = () => {
    localStorage.setItem("activeTab", "plan");
    navigate("/user/setting");
  };

  return (
    <div className="fixed container-fluid p-0 custom-bg-root h-screen max-h-screen">
      <div className="h-full flex">
        {(!isMobile || showMobileSidebar) && (
          <Sidebar
            handleUpgradePlan={handleUpgradePlan}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            isMobile={isMobile}
          />
        )}

        <div className="flex-1 md:m-2 flex flex-col h-full bg-white rounded-lg">
          <Outlet
            context={{
              setIsCollapsed,
              isCollapsed,
              isMobile,
              logout,
              setShowMobileSidebar,
              handleUpgradePlan
            }}
          />
        </div>
        {isMobile && <MobileNavbar onLogout={logout} handleUpgradePlan={handleUpgradePlan} />}
      </div>
      <ChatModal   />
    </div>
  );
};

export default Layout; 