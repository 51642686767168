import React from 'react';
import axios from "axios";
import Helpers from "../../Config/Helpers";
import { auth, googleProvider, signInWithPopup } from '../../Components/firebase';

const GoogleSignIn = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const handleGoogleSignIn = async () => {
    try {
      setIsLoading(true);
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      const idToken = await user.getIdToken();
      const response = await axios.post(`${Helpers.apiUrl}api/google-signin`, { idToken });

      Helpers.toast("success", response.data.message);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("user_id", JSON.stringify(response.data.user.id));
      localStorage.setItem("token", response.data.token);
      const loginTimestamp = new Date().getTime();
      localStorage.setItem("loginTimestamp", loginTimestamp);
      setIsLoading(false);
      window.location.href = "/chat/chat-interface";
    } catch (error) {
      setIsLoading(false);
        if (error.response) {
            console.error("Response Error:", error.response.data);
        } else if (error.request) {
            console.error("Request Error:", error.request);
        } else {
            console.error("General Error:", error.message);
        }
    }
  };
  return (
        <button onClick={handleGoogleSignIn} className="btn btn-danger btn-block">
          <i className="fab fa-google"></i>   {isLoading ? "Please wait..." : "Sign in with Google"}
        </button>
      );
};

export default GoogleSignIn;