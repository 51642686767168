import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Helpers from '../../../Config/Helpers';
import { usePlan } from '../../../Context/PlanContext';

const Profile = () => {
    const { userData, getProfileInfo } = usePlan();
    const [isLoading, setIsLoading] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const defaultPass = {
        password: "",
        password_confirmation: "",
      };
    const [password, setPassword] = useState(defaultPass); 
    const [errors, setErrors] = useState({});

  const updateProfilePic = (e) => {
    setIsLoading(true);
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("profile_pic", file);
    axios
      .post(
        `${Helpers.apiUrl}user/update-picture`,
        formData,
        Helpers.authFileHeaders
      )
      .then((response) => {
        setIsLoading(false);
       getProfileInfo()
      })
      .catch((error) => {
        Helpers.toast("error", error.response.data.message);
        setIsLoading(false);
      });
  };
  const updatePasswordHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(
        `${Helpers.apiUrl}user/update-password`,
        password,
        Helpers.authHeaders
      )
      .then((response) => {
        Helpers.toast("success", response.data.message);
        setUpdatePassword(false);
        setPassword(defaultPass);
        setIsLoading(false);
      })
      .catch((error) => {
        Helpers.toast("error", error.response.data.message);
        setErrors(error.response.data.errors || {});
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProfileInfo();
  }, [getProfileInfo]);


  return (
    <div className="lg:w-1/2 w-full">
    <h1 className="text-2xl font-bold mb-8">Profile Settings</h1>
    
    <div className="rounded-lg p-6">
      {!updatePassword ? (
        <div className="space-y-8">
          <div className="flex flex-col items-center">
            <div className="relative">
              <div className="w-20 h-20 rounded-full bg-[#6439F5] flex items-center justify-center text-white text-3xl font-semibold">
                {userData?.name?.charAt(0)?.toUpperCase()}
              </div>
              {userData?.profile_pic && (
                <img
                  className="absolute inset-0 w-20 h-20 rounded-full object-cover"
                  src={Helpers.serverImage(userData?.profile_pic)}
                  alt="Profile"
                />
              )}
            </div>
            <div className="mt-4 text-center">
              <h2 className="text-xl font-semibold">{userData?.name}</h2>
              <p className="text-gray-600">{userData?.email}</p>
            </div>
            
            <div>
              <label
                htmlFor="profile_picture_select"
                className="inline-flex items-center px-2 my-2 py-2 bg-[#6439F5] text-white rounded-md hover:bg-[#5331d4] cursor-pointer"
              >
                Update Profile Picture
              </label>
              <input
                id="profile_picture_select"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={updateProfilePic}
              />
            </div>
          </div>

          {/* Profile Form */}
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name
              </label>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded-md"
                placeholder="Name"
                value={userData?.name?.split(' ')[0] || ''}
                readOnly
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded-md"
                placeholder="Last Name"
                value={userData?.email}
                readOnly
              />
            </div>

            <div>
  
            <button
              className="text-[#6439F5] hover:text-[#5331d4] mt-2"
              onClick={() => setUpdatePassword(true)}
            >
              Change Password
            </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="max-w-md mx-auto">
          <div className="flex items-center mb-6">
            <button
              className="text-gray-600 hover:text-gray-800 mr-3"
              onClick={() => setUpdatePassword(false)}
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <h3 className="text-xl font-semibold">Update Your Password</h3>
          </div>

          <form onSubmit={updatePasswordHandler} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                New Password
              </label>
              <input
                type="password"
                value={password.password}
                onChange={(e) =>
                  setPassword({
                    ...password,
                    password: e.target.value,
                  })
                }
                className="w-full p-2 border border-gray-300 rounded-md"
                placeholder="Enter New Password"
              />
              {errors.password && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.password[0]}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Confirm Password
              </label>
              <input
                type="password"
                value={password.password_confirmation}
                onChange={(e) =>
                  setPassword({
                    ...password,
                    password_confirmation: e.target.value,
                  })
                }
                className="w-full p-2 border border-gray-300 rounded-md"
                placeholder="Confirm Your Password"
              />
              {errors.password_confirmation && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.password_confirmation[0]}
                </p>
              )}
            </div>

            <div className="flex justify-end gap-3">
              <button
                type="button"
                className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
                onClick={() => setUpdatePassword(false)}
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-[#6439F5] rounded-md hover:bg-[#5331d4]"
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save New Password"}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  </div>
  )
}

export default Profile