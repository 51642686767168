import React, { createContext, useContext, useState, useEffect } from "react";
import Helpers from "../Config/Helpers";
import axios from "axios";
// Create Context
const ChatsContext = createContext();

// Provider Component
export const ChatsProvider = ({ children }) => {
  const [userChats, setUserChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState("Chats");

  const getPreviousChats = async () => {
    try {
      const userString = localStorage.getItem("user");
      if (userString) {
        const user_chats = await axios.get(
          `${Helpers.apiUrl}chat/all`,
          Helpers.authHeaders
        );
        setUserChats(user_chats.data);
      } else {
        setSelectedChat("Chats");
      }
    } catch (e) {
      console.error("Error: ", e);
      setSelectedChat("Chats");
    }
  };

  useEffect(() => {
    getPreviousChats();
  }, []);

  const deleteChat = async (chatId) => {
    try {
      await axios.delete(
        `${Helpers.apiUrl}chat/delete/${chatId}`,
        Helpers.authHeaders
      );

      // Update chats state
      setUserChats((prev) => prev.filter((chat) => chat.id !== chatId));
      return true;
    } catch (error) {
      console.error("Error deleting chat:", error);
      Helpers.toast("error", "Failed to delete chat");
      return false;
    }
  };

  const renameChat = async (chatId, newTitle) => {
    try {
      await axios.put(
        `${Helpers.apiUrl}chat/rename/${chatId}`,
        { title: newTitle },
        Helpers.authHeaders
      );

      // Update chats state
      setUserChats((prev) =>
        prev.map((chat) =>
          chat.id === chatId ? { ...chat, title: newTitle } : chat
        )
      );
      return true;
    } catch (error) {
      console.error("Error renaming chat:", error);
      Helpers.toast("error", "Failed to rename chat");
      return false;
    }
  };

  return (
    <ChatsContext.Provider
      value={{
        selectedChat,
        setSelectedChat,
        setUserChats,
        userChats,
        getPreviousChats,
        deleteChat,
        renameChat,
      }}
    >
      {children}
    </ChatsContext.Provider>
  );
};

// Custom Hook to Use Context
export const useChatsContext = () => {
  return useContext(ChatsContext);
};
