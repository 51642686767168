import React, { useEffect, useState } from "react";
import axios from "axios";
import Helpers from "../../../Config/Helpers";
import { usePlan } from "../../../Context/PlanContext";

const Organization = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [showAddMember, setShowAddMember] = useState(false);
  const [members, setMembers] = useState([]);
  const { userData } = usePlan();
  const defaultMember = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    is_org_owner: false,
    is_active: true,
    org_id: Helpers.authUser.org_id,
    permissions: 1,
  };
  const [memberData, setMemberData] = useState(defaultMember);
  const saveMember = () => {
    if (
      memberData.password &&
      memberData.password !== memberData.password_confirmation
    ) {
      setErrors({ password_confirmation: "Passwords do not match" });
      return;
    }
    setIsLoading(true);
    axios
      .post(
        `${Helpers.apiUrl}members/save-member`,
        memberData,
        Helpers.authHeaders
      )
      .then((response) => {
        setIsEditing(false);
        setShowAddMember(false);
        setIsLoading(false);
        getOrganizationMembers();
        setMemberData(defaultMember);
        Helpers.toast("success", response.data.message);
      })
      .catch((error) => {
        console.error("Error saving member:", error);
        const errorMessage =
          error.response?.data?.error ||
          error.response?.data?.message ||
          "An unknown error occurred.";
        setErrors(errorMessage);
        Helpers.toast("error", errorMessage);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getOrganizationMembers();
  }, []);
  const getOrganizationMembers = () => {
    axios
      .get(`${Helpers.apiUrl}members/all-members`, Helpers.authHeaders)
      .then((response) => {
        setMembers(response.data);
      })
      .catch((error) => {
        Helpers.toast("error", error.response?.data?.message);
        console.error("Error fetching members:", error);
      });
  };
  const editMember = (member) => {
    setMemberData({
      name: member.name,
      email: member.email,
      is_org_owner: member.is_org_owner,
      is_active: member.is_active,
      id: member.id,
      password: "",
      password_confirmation: "",
      permissions: member.permissions,
    });
    setShowAddMember(true);
    setIsEditing(true);
  };

  const deleteMember = (id) => {
    axios
      .delete(`${Helpers.apiUrl}members/delete/${id}`, Helpers.authHeaders)
      .then((response) => {
        Helpers.toast("success", response.data.message);
        getOrganizationMembers();
      })
      .catch((error) => {
        console.error("Error deleting member:", error);
      });
  };
  return (
    <div className="w-full">
      {userData.is_org_owner && (
        <div className="flex justify-between items-center mb-1 my-auto">
          <h2 className="text-xl font-semibold">Organization Members</h2>
          <button
            className="btn btn-primary"
            onClick={() => setShowAddMember(true)}
          >
            Add New Member
          </button>
        </div>
      )}

      {showAddMember && (
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-4">
            {isEditing ? "Edit Member" : "Add New Member"}
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700">Name</label>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={memberData.name}
                onChange={(e) =>
                  setMemberData({ ...memberData, name: e.target.value })
                }
              />
            </div>
            <div>
              <label className="block text-gray-700">Email</label>
              <input
                type="email"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={memberData.email}
                onChange={(e) =>
                  setMemberData({ ...memberData, email: e.target.value })
                }
              />
            </div>
            <div>
              <label className="block text-gray-700">Password</label>
              <input
                type="password"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={memberData.password}
                onChange={(e) =>
                  setMemberData({
                    ...memberData,
                    password: e.target.value,
                  })
                }
              />
              {errors.password && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.password[0]}
                </p>
              )}
            </div>
            <div>
              <label className="block text-gray-700">Confirm Password</label>
              <input
                type="password"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={memberData.password_confirmation}
                onChange={(e) =>
                  setMemberData({
                    ...memberData,
                    password_confirmation: e.target.value,
                  })
                }
              />{" "}
              {errors.password_confirmation && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.password_confirmation[0]}
                </p>
              )}
            </div>
            <div>
              <label className="block text-gray-700">Role</label>
              <select
                className="w-full p-2 border border-gray-300 rounded-md"
                value={memberData.is_org_owner}
                onChange={(e) =>
                  setMemberData({
                    ...memberData,
                    is_org_owner: e.target.value,
                  })
                }
              >
                <option value={false}>Member</option>
                <option value={true}>Owner</option>
              </select>
            </div>
            <div>
              <label className="block text-gray-700">Permissions</label>
              <select
                className="w-full p-2 border border-gray-300 rounded-md"
                value={memberData.permissions}
                onChange={(e) =>
                  setMemberData({
                    ...memberData,
                    permissions: e.target.value,
                  })
                }
              >
                <option value={1}>Read</option>
                <option value={2}>Read/Write</option>
              </select>
            </div>
          </div>

          <div className="flex justify-end gap-4 mt-4">
            <button
              className="btn btn-primary"
              onClick={saveMember}
              disabled={isLoading}
            >
              {isLoading
                ? "Saving..."
                : isEditing
                ? "Save Changes"
                : "Add Member"}
            </button>
            <button
              className="btn btn-outline-danger"
              onClick={() => {
                setShowAddMember(false);
                setMemberData(defaultMember);
                setIsEditing(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      <div className="w-full overflow-x-auto">
        {!showAddMember && (
          <div className="min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">
                    Name
                  </th>
                  <th className="px-4 py-3 text-left text-sm font-medium text-gray-700 hidden md:table-cell">
                    Email
                  </th>
                  <th className="px-4 py-3 text-left text-sm font-medium text-gray-700 hidden sm:table-cell">
                    Role
                  </th>
                  <th className="px-4 py-3 text-right text-sm font-medium text-gray-700">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {members.length === 0 ? (
                  <tr>
                    <td colSpan={4} className="text-center py-4 text-gray-500">
                      No members found.
                    </td>
                  </tr>
                ) : (
                  members.map((member) => (
                    <tr key={member.id} className="hover:bg-gray-50">
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div className="flex flex-col">
                          <div className="text-sm font-medium text-gray-900">
                            {member.name}
                          </div>
                          <div className="text-sm text-gray-500 md:hidden">
                            {member.email}
                          </div>
                          <div className="text-sm text-gray-500 sm:hidden">
                            {member.is_org_owner ? "Owner" : "Member"}
                          </div>
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap hidden md:table-cell">
                        <div className="text-sm text-gray-900">
                          {member.email}
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap hidden sm:table-cell">
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                                ${
                                  member.is_org_owner
                                    ? "bg-green-100 text-green-800"
                                    : "bg-gray-100 text-gray-800"
                                }`}
                        >
                          {member.is_org_owner ? "Owner" : "Member"}
                        </span>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          className="text-blue-500 hover:text-blue-700 mr-3"
                          onClick={() => editMember(member)}
                        >
                          Edit
                        </button>
                        <button
                          className="text-red-500 hover:text-red-700"
                          onClick={() => deleteMember(member.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Organization;
