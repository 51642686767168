import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import { PiSuitcaseSimpleLight } from "react-icons/pi";
import Navbar from "../../../Components/Navbar";
import { Pencil } from "lucide-react";
import { ChatAppContext } from "../../../Context/AppContext";
import { usePlan } from "../../../Context/PlanContext";

const LandingPage = () => {
  const { isCollapsed, setIsCollapsed, logout, handleUpgradePlan } =
    useOutletContext();
  const { setShowChatModal, handleChatModalShow } = useContext(ChatAppContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };
  const [planState, setplanState] = useState("");

  const { isPlanActive } = usePlan();
  useEffect(() => {
    isPlanActive && isPlanActive === "Active"
      ? setplanState("Paid")
      : isPlanActive === "Expired"
      ? setplanState("Expired")
      : setplanState("Free");
  }, [isPlanActive]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowChatModal(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, [setShowChatModal]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      <Navbar
        handleUpgradePlan={handleUpgradePlan}
        isCollapsed={isCollapsed}
        toggleCollapse={toggleCollapse}
        planState={planState}
        onLogout={logout}
        toggleModelDialog={toggleDialog}
      />

      <div className="overflow-y-auto w-full h-[calc(100vh-116px)] sm:h-[calc(100vh-76px)] scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1] scrollbar-thin px-2">
        <div className="flex justify-center items-center h-[calc(90vh-60px)]">
          <div className="py-4 flex flex-col justify-center">
            <div className="flex flex-col gap-3 relative">
              <div className="flex items-center gap-3">
                <div id="diagram"></div>
              </div>
              <div className="bg-gray-100 rounded p-3 flex flex-col sm:flex-row justify-between items-start text-center sm:text-left">
                <p className="text-gray-800 font-bold text-lg mb-3 sm:mb-0">
                  Upload Your Document to Get Started
                </p>
                <button
                  onClick={handleChatModalShow}
                  className="flex items-center justify-center bg-gray-800 text-white py-2 px-4 rounded gap-3 w-full sm:w-auto"
                >
                  <FiUpload />
                  Upload
                </button>
              </div>
              <div>
                <div className="flex gap-3 items-center mb-3 justify-between">
                  <p className="text-lg font-medium">
                    Or pick from one of the use cases below
                  </p>
                </div>
                <div className="flex flex-wrap justify-evenly gap-3">
                  <div
                    className={`border rounded p-3 flex flex-col justify-between ${
                      planState === "Paid"
                        ? "hover:bg-gray-100 cursor-pointer"
                        : ""
                    }`}
                    style={{ minWidth: "190px" }}
                    onClick={planState === "Paid" ? handleChatModalShow : null}
                  >
                    <div className="flex justify-between">
                      <PiSuitcaseSimpleLight className="text-xl" />
                      {planState !== "Paid" && (
                        <button onClick={handleUpgradePlan}>
                          <span className="px-2 py-1 text-sm bg-blue-500 text-white rounded">
                            Upgrade
                          </span>
                        </button>
                      )}
                    </div>
                    <h5 className="text-gray-500 mt-2 mb-1 font-bold">
                      Analyze Reports
                    </h5>
                    <p className="text-gray-500">
                      Analyze key data, extract vital info, & AI-ction!
                    </p>
                  </div>
                  <div
                    className={`border rounded p-3 flex flex-col justify-between ${
                      planState === "Paid"
                        ? "hover:bg-gray-100 cursor-pointer"
                        : ""
                    }`}
                    style={{ minWidth: "190px" }}
                    onClick={planState === "Paid" ? handleChatModalShow : null}
                  >
                    <div className="flex justify-between">
                      <Pencil
                        size={24}
                        className="text-gray-600"
                        strokeWidth={1.33}
                      />
                      {planState !== "Paid" && (
                        <button onClick={handleUpgradePlan}>
                          <span className="px-2 py-1 text-sm bg-blue-500 text-white rounded">
                            Upgrade
                          </span>
                        </button>
                      )}
                    </div>
                    <h5 className="text-gray-500 mt-2 mb-1 font-bold">
                      Generate Insights
                    </h5>
                    <p className="text-gray-500">
                      Get actionable insights & improve strategies!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
