import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import Helpers from "../Config/Helpers";
import axios from "axios";

const FolderContext = createContext();
export const FolderProvider = ({ children }) => {
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [showFolders, setShowFolders] = useState(false);


  const getFolders = useCallback(async () => {
    try {
      const response = await axios.get(
        `${Helpers.apiUrl}folders`,
        Helpers.authHeaders
      );
      setFolders(response.data);

    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  },[]);
  useEffect(() => {
    if (showFolders && folders.length > 0) {
      setSelectedFolder(folders[0].id);
    } else {
      setSelectedFolder(null); // Reset folder selection when hiding
    }
  }, [showFolders, folders]);

  // Create a new folder
  const createFolder = async (folderName) => {
    if (!folderName) return false;
    
    try {
      const response = await axios.post(
        `${Helpers.apiUrl}folders`,
        { name: folderName }, Helpers.authHeaders
      );
      
      setFolders(prev => [...prev, response.data]);
      Helpers.toast("success", "Collection created successfully!");
      return true;
    } catch (error) {
      console.error("Error creating folder:", error);
      Helpers.toast("error", "Failed to create collection.");
      return false;
    }
  };

  // Delete a folder
  const deleteFolder = async (folderId) => {
    try {
      await axios.delete(
        `${Helpers.apiUrl}folders/${folderId}`,
        Helpers.authHeaders
      );
      
      // Update folders state immediately
      setFolders(prev => prev.filter(folder => folder.id !== folderId));
      Helpers.toast("success", "Collection deleted successfully!");
      return true;
    } catch (error) {
      console.error("Error deleting folder:", error);
      Helpers.toast("error", "Failed to delete collection.");
      return false;
    }
  };

  // Add rename folder function
  const renameFolder = async (folderId, newName) => {
    try {
      await axios.put(
        `${Helpers.apiUrl}folders/${folderId}`,
        { name: newName }, Helpers.authHeaders
      );
      
      getFolders();      
      Helpers.toast("success", "Folder renamed successfully!");
      return true;
    } catch (error) {
      console.error("Error renaming folder:", error);
      Helpers.toast("error", "Failed to rename folder.");
      return false;
    }
  };

  const bulkDeleteFolders = async (folderIds) => {
    try {
      await axios.post(
        `${Helpers.apiUrl}folders/bulk-delete`,
        { ids: folderIds },
        {
          headers: Helpers.authHeaders.headers
        }
      );
      
      setFolders((prev) => prev.filter((folder) => !folderIds.includes(folder.id)));
      Helpers.toast("success", "Selected folders deleted successfully!");
      return true;
    } catch (error) {
      console.error("Error deleting folders:", error);
      Helpers.toast("error", "Failed to delete selected folders.");
      return false;
    }
  };

  useEffect(() => {
    getFolders();
  }, [getFolders]);

  return (
    <FolderContext.Provider
      value={{
        folders,
        setFolders,
        getFolders,
        selectedFolder,
        showFolders,
        setShowFolders,
        setSelectedFolder,
        createFolder,
        deleteFolder,
        renameFolder,
        bulkDeleteFolders,
      }}
    >
      {children}
    </FolderContext.Provider>
  );
};

export const useFolders = () => useContext(FolderContext);
