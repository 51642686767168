import React, { useEffect, useState } from "react";
import { Boxes, Info, RefreshCw, Users, X } from "lucide-react";
import { FaCoins } from "react-icons/fa";
import axios from "axios";
import LayoutLoader from "../../../Components/Loader/LayoutLoader";
import Helpers from "../../../Config/Helpers";
import dayjs from "dayjs";
import { usePlan } from "../../../Context/PlanContext";
import { IoIosRocket } from "react-icons/io";

const getNestedData = (data) => {
  while (data && typeof data === "object" && "data" in data) {
    data = data.data;
  }
  return data;
};

const Plans = () => {
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [selectedCreditPackage, setSelectedCreditPackage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [tokenPackages, setTokenPackages] = useState([]);
  const [plans, setPlans] = useState([]);
  const {
    isPlanActive,
    userData,
    availableTokens,
    Transaction,
    getProfileInfo,
  } = usePlan();
  const isPlanExpired = isPlanActive === "Expired";
  const [planState, setplanState] = useState("");
  useEffect(() => {
    isPlanActive && isPlanActive === "Active"
      ? setplanState("Paid")
      : isPlanActive === "Expired"
      ? setplanState("Expired")
      : setplanState("Free");
  }, [isPlanActive]);
  const getTokenPackages = async () => {
    try {
      const response = await axios.get(
        `${Helpers.apiUrl}token-packages`,
        Helpers.authHeaders
      );
      setTokenPackages(response.data);
    } catch (error) {
      console.error("Error Fetching Token Packages:", error);
    }
  };

  useEffect(() => {
    if (showPlansModal || showCreditsModal || showUnsubscribeModal) {
      document.body.style.overflow = "hidden"; // Disable scroll
    } else {
      document.body.style.overflow = ""; // Restore scroll
    }
  }, [showPlansModal, showCreditsModal, showUnsubscribeModal]);

  const handleBuyTokens = async (tokenPackage) => {
    const currentDate = dayjs().format("YYYY-MM-DD");

    const data = {
      user_id: Helpers.authUserId,
      package_id: tokenPackage.id,
      amount: tokenPackage.price,
      purchase_date: currentDate,
    };

    localStorage.setItem("selectedPlan", JSON.stringify(tokenPackage));
    localStorage.setItem("actionType", "buyTokens");

    setLoader(true);
    try {
      const response = await axios.post(
        `${Helpers.apiUrl}pay`,
        { amount: data.amount },
        Helpers.authHeaders
      );
      const finalData = getNestedData(response.data);
      window.location.href = finalData.authorization_url;
      setLoader(false);
    } catch (error) {
      Helpers.toast("error", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getPricingPlans();
    getTokenPackages();
  }, []);

  const getPricingPlans = async () => {
    try {
      setLoader(true);
      const response = await axios.get(
        `${Helpers.apiUrl}packages/all-packages`,
        Helpers.authHeaders
      );
      setPlans(response.data);
      setLoader(false);
    } catch (error) {
      console.error("Error Fetching Plans:", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getPricingPlans();
  }, []);

  const filteredPlans = plans.filter((plan) => {
    if (
      userData?.org_id == null &&
      !userData?.is_org_owner &&
      userData?.transactions.length === 0
    ) {
      return true;
    } else if (
      userData?.org_id == null &&
      !userData?.is_org_owner &&
      userData?.transactions != null
    ) {
      return plan.package_type === "Single User";
    } else if (userData?.is_org_owner && userData?.org_id) {
      return plan.package_type === "Organization";
    }
    return true;
  });

  if (loader) {
    return (
        <LayoutLoader />
    );
  }
  const unsubscribeTransaction = () => {
    axios
      .post(
        `${Helpers.apiUrl}transactions/unsubscribe`,
        {},
        Helpers.authHeaders
      )
      .then((response) => {
        Helpers.toast("success", response.data.message);
        setShowPlansModal(true);
        getProfileInfo();
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message ||
          "Failed to unsubscribe. Please try again.";
        Helpers.toast("error", errorMessage);
        console.error("Error unsubscribing transaction:", error);
      });
  };

  return (
    <>
      <div className="p-6">
        <div className="max-w-7xl mx-auto">
          <div className="mb-8">
            <h1 className="text-2xl font-semibold mb-2">
              Hey, Document Superhero 👋
            </h1>
          </div>

          <div className="bg-white rounded-lg border border-[#F1F2F6] p-6 mb-8">
            <div className="bg-[#EAE5FF] bg-opacity-30 rounded-lg p-6 mb-6">
              <div className="flex justify-between flex-col md:flex-row items-center gap-2">
                <div>
                  <p className="text-gray-600 mb-1">Active Plan:</p>
                  <h2 className="text-2xl font-bold">{planState}</h2>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setShowPlansModal(true)}
                    className="bg-[#6439F5] text-white px-6 py-2 rounded-lg hover:bg-[#5331d4] flex items-center gap-2"
                  >
                    <IoIosRocket />
                    Upgrade Plan
                  </button>
                  {(planState !== "Free" && planState !== 'Expired') && (
                    <button
                      onClick={() => setShowUnsubscribeModal(true)}
                      className="bg-[#6439F5] text-white px-6 py-2 rounded-lg hover:bg-[#5331d4] flex items-center gap-2"
                    >
                      Unsubscribe
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="rounded-lg border border-[#F1F2F6] p-3 mb-2 overflow-y-scroll h-[calc(100vh-365px)]">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 my-6">
                <StatsCard
                  icon={<Boxes className="h-5 w-5 text-[#6439F5]" />}
                  title="Document History"
                  value={userData?.chats?.length}
                  bgColor="bg-[#cae5fd]"
                />
                <StatsCard
                  icon={<Users className="h-5 w-5 text-[#6439F5]" />}
                  title="Type"
                  value={
                    Transaction?.is_active === 1 &&
                    Transaction?.package?.package_type
                      ? Transaction.package.package_type
                      : "N/A"
                  }
                  bgColor="bg-[#D1CAFD]"
                />
                <StatsCard
                  icon={<FaCoins className="h-5 w-5 text-[#6439F5]" />}
                  title="Credits"
                  value={
                    Transaction?.is_active === 1
                      ? userData?.org_id
                        ? userData?.organization?.no_tokens
                        : userData?.no_tokens
                      : "0"
                  }
                  bgColor="bg-[#DCF2EA]"
                />
              </div>

              <PlanDetails />
              {!isPlanExpired && (
                <CreditsSection
                  onBuyCredits={() => setShowCreditsModal(true)}
                  creditsUsed={Math.max(availableTokens, 0)}
                  getProfileInfo={getProfileInfo}
                  totalCredits={
                    userData?.org_id
                      ? userData?.organization?.no_tokens
                      : userData?.no_tokens
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        isOpen={showUnsubscribeModal}
        onClose={() => setShowUnsubscribeModal(false)}
        onConfirm={() => unsubscribeTransaction()}
        title="Unsubscribe Plan"
        message="Are you sure you want to unsubscribe from the current plan? This action cannot be undone."
      />
      {showPlansModal && (
        <Modal onClose={() => setShowPlansModal(false)} title="Take Your Pick!">
          <div className="mb-4">
            <p className="text-gray-600">
              Become 10x productive in a single click. Upgrade now!
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {filteredPlans.map((plan) => (
              <PlanCard key={plan.package_name} plan={plan} />
            ))}
          </div>
        </Modal>
      )}

      {showCreditsModal && (
        <Modal
          onClose={() => {
            setShowCreditsModal(false);
            setSelectedCreditPackage(null);
          }}
          title="Buy More Credits"
          tokenModel={true}
        >
          <div className="space-y-4">
            {tokenPackages.map((pkg, index) => (
              <CreditPackageCard
                key={index}
                package={pkg}
                onSelect={setSelectedCreditPackage}
                selectedPackage={selectedCreditPackage}
              />
            ))}
          </div>
          <div className="mt-6 flex justify-end">
            <button
              onClick={() => handleBuyTokens(selectedCreditPackage)}
              disabled={!selectedCreditPackage}
              className="bg-[#6439F5] text-white px-6 py-2 rounded-lg hover:bg-[#5331d4] disabled:bg-gray-300 disabled:cursor-not-allowed transition"
            >
              Purchase Credits
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-md p-6">
        <h3 className="text-xl font-bold mb-4">{title}</h3>
        <p className="text-gray-600 mb-6">{message}</p>
        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const StatsCard = ({ icon, title, value, bgColor }) => (
  <div className="flex flex-col">
    <span
      className={`w-8 h-8 flex items-center justify-center ${bgColor} rounded-full mb-3`}
    >
      {icon}
    </span>
    <p className="text-gray-600">{title}</p>
    <p className="text-xl text-gray-700 font-bold">{value}</p>
  </div>
);

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const PlanDetails = () => {
  const { Transaction, userData } = usePlan();
  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-4">Plan Details</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <DetailRow
          label="Plan Type"
          value={
            Transaction?.is_active === 1 && Transaction?.package?.package_type
              ? Transaction.package.package_type
              : "N/A"
          }
        />
        <DetailRow
          label="Last Payment"
          value={
            Transaction?.is_active === 1 && Transaction?.created_at
              ? formatDate(Transaction.created_at)
              : "N/A"
          }
        />
        <DetailRow
          label="Account Created"
          value={
            Transaction?.is_active === 1 && userData?.created_at
              ? formatDate(userData.created_at)
              : "N/A"
          }
        />
        <DetailRow
          label="Next Payment"
          value={
            Transaction?.is_active === 1 && Transaction?.created_at
              ? (() => {
                  const date = new Date(Transaction.created_at);
                  date.setMonth(date.getMonth() + 1);
                  return formatDate(date.toISOString());
                })()
              : "N/A"
          }
        />
      </div>
    </div>
  );
};

const CreditsSection = ({
  onBuyCredits,
  creditsUsed,
  totalCredits,
  getProfileInfo,
}) => (
  <div>
    <div className="flex items-center justify-between py-2 mb-2">
      <div className="flex items-center gap-2">
        <h3 className="text-lg font-semibold">Credits Utilized</h3>
        <Info className="h-5 w-5 text-gray-400" />
      </div>
      <div className="flex items-center gap-2">
        <span>
          {creditsUsed}/{totalCredits}
        </span>
        <RefreshCw
          className="h-5 w-5 cursor-pointer"
          onClick={getProfileInfo}
        />
      </div>
    </div>
    <div className="relative h-2 bg-gray-200 rounded-full mb-4">
      <div
        className="absolute h-full bg-gradient-to-r from-[#6439F5] to-[#B8DBFC] rounded-full"
        style={{ width: `${(creditsUsed / totalCredits) * 100}%` }}
      />
    </div>
    <div className="text-right pb-2">
      <button
        onClick={onBuyCredits}
        className="text-[#6439F5] text-sm font-medium hover:underline"
      >
        Buy More Credits
      </button>
    </div>
  </div>
);

const PlanCard = ({ plan }) => {
  const { Transaction, isPlanActive, userData } = usePlan();
  const isActivePlan = plan.id === Transaction?.package?.id;
  const isPlanExpired = isPlanActive === "Expired";
  const [loader, setLoader] = useState(false);
  const [OrgName, setOrgName] = useState("");
  const [showOrgNamePopup, setShowOrgNamePopup] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handlePlanSelection = () => {
    if (isActivePlan && !isPlanExpired) {
      Helpers.toast("info", "This is your current active plan");
      return;
    }
    handleBuyPlan(plan);
  };

  const handleBuyPlan = async (plan) => {
    if (userData?.org_id !== null && userData?.organization?.org_name) {
      proceedWithTransaction(plan, userData?.organization?.org_name);
    } else if (plan.package_type === "Organization") {
      setSelectedPlan(plan);
      setShowOrgNamePopup(true);
    } else {
      proceedWithTransaction(plan);
    }
  };

  const proceedWithTransaction = async (plan, orgName = null) => {
    const currentDate = dayjs().format("YYYY-MM-DD");

    const data = {
      user_id: Helpers.authUserId,
      package_id: plan.id,
      amount: plan.package_price,
      purchase_date: currentDate,
      org_name: orgName ? orgName : undefined,
    };

    localStorage.setItem("selectedPlan", JSON.stringify(plan));
    localStorage.setItem("actionType", "buyPlan");

    setLoader(true);
    try {
      const response = await axios.post(
        `${Helpers.apiUrl}pay`,
        { amount: data.amount },
        Helpers.authHeaders
      );
      const finalData = getNestedData(response.data);
      window.location.href = finalData.authorization_url;
      setLoader(false);
    } catch (error) {
      if (localStorage.getItem("actionType")) {
        localStorage.removeItem("actionType");
      }
      Helpers.toast("error", error);
      setLoader(false);
    }
  };

  const handleOrgNameSubmit = () => {
    if (OrgName.trim() === "") {
      Helpers.toast("error", "Please enter the organization's name");
      return;
    }
    localStorage.setItem("organizationName", OrgName);
    proceedWithTransaction(selectedPlan, OrgName);
    setShowOrgNamePopup(false);
    setOrgName("");
  };

  return (
    <>
      <div
        className={`rounded-lg p-6 relative ${
          isActivePlan
            ? isPlanExpired
              ? "bg-white border-2 border-orange-400"
              : "bg-[#6439F5] text-white"
            : "bg-white border border-gray-200"
        }`}
      >
        {loader && (
          <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center rounded-lg">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#6439F5]"></div>
          </div>
        )}

        <div className="relative">
          {isActivePlan && (
            <span
              className={`absolute -top-2 right-0 text-xs px-2 py-1 rounded-full ${
                isPlanExpired
                  ? "bg-orange-100 text-orange-600"
                  : "bg-white text-[#6439F5]"
              }`}
            >
              {isPlanExpired ? "Expired" : "Active"}
            </span>
          )}
          <h3
            className={`text-xl font-bold mb-1 ${
              isActivePlan && !isPlanExpired ? "text-white" : ""
            }`}
          >
            {plan.package_name}
          </h3>
          <p className="text-sm mb-4">{plan.package_type}</p>
        </div>

        <div className="flex flex-col gap-2 justify-between items-center mb-4">
          <div className="mb-2">
            <span className="text-3xl font-bold">${plan.package_price}</span>
            <span className="text-sm">/mo</span>
          </div>

          <div className="flex items-center gap-4">
            <div className="flex items-center">
              <i
                className={`fas fa-${
                  plan.package_users === 1 ? "user" : "users"
                } ${
                  isActivePlan && !isPlanExpired
                    ? "text-white"
                    : "text-gray-600 mr-2"
                }`}
              />
              <p
                className={`px-2 ${
                  isActivePlan && !isPlanExpired
                    ? "text-white"
                    : "text-gray-700"
                }`}
              >
                {plan.package_users}
              </p>
            </div>
            <div className="flex items-center gap-1">
              <i
                className={`fa fa-coins ${
                  isActivePlan && !isPlanExpired
                    ? "text-white"
                    : "text-gray-600"
                }`}
              />
              <p className="text-md font-semibold">{plan.no_tokens}</p>
            </div>
          </div>
        </div>

        <ul className="space-y-3 mb-6">
          {plan.package_description.split(",").map((feature, index) => (
            <li key={index} className="flex items-center gap-2">
              <i
                className={`fas fa-check ${
                  isActivePlan && !isPlanExpired
                    ? "text-white"
                    : "text-green-500"
                }`}
              />
              <span
                className={
                  isActivePlan && !isPlanExpired
                    ? "text-white"
                    : "text-gray-600"
                }
              >
                {feature.trim()}
              </span>
            </li>
          ))}
        </ul>

        <button
          onClick={handlePlanSelection}
          disabled={isActivePlan && (!isPlanExpired || loader)}
          className={`w-full py-2 rounded-md ${
            isActivePlan
              ? isPlanExpired
                ? "bg-orange-500 text-white hover:bg-orange-600"
                : "bg-white text-[#6439F5] cursor-not-allowed"
              : "bg-[#6439F5] text-white hover:bg-[#5331d4]"
          } transition disabled:opacity-50 disabled:cursor-not-allowed`}
        >
          {loader ? (
            <span className="flex items-center justify-center gap-2">
              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
              Processing...
            </span>
          ) : isActivePlan ? (
            isPlanExpired ? (
              "Renew Plan"
            ) : (
              "Active Plan"
            )
          ) : (
            "Get Started"
          )}
        </button>
      </div>

      {showOrgNamePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Enter Organization Name</h2>
              <button
                onClick={() => setShowOrgNamePopup(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Organization Name
                </label>
                <input
                  type="text"
                  value={OrgName}
                  onChange={(e) => setOrgName(e.target.value)}
                  placeholder="Enter your organization name"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-[#6439F5] focus:border-[#6439F5]"
                />
              </div>

              <div className="flex justify-end gap-3">
                <button
                  onClick={() => setShowOrgNamePopup(false)}
                  className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={handleOrgNameSubmit}
                  disabled={!OrgName.trim() || loader}
                  className="px-4 py-2 bg-[#6439F5] text-white rounded-md hover:bg-[#5331d4] disabled:bg-gray-300 disabled:cursor-not-allowed"
                >
                  {loader ? (
                    <span className="flex items-center gap-2">
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                      Processing...
                    </span>
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const CreditPackageCard = ({
  package: tokenPackage,
  onSelect,
  selectedPackage,
}) => {
  const isSelected = selectedPackage?.id === tokenPackage.id;

  return (
    <label
      className={`flex items-center justify-between p-4 border rounded-lg cursor-pointer transition-all ${
        isSelected
          ? "border-[#6439F5] bg-[#6439F5] bg-opacity-5"
          : "hover:border-[#6439F5]"
      }`}
    >
      <div className="flex items-center gap-4">
        <input
          type="radio"
          name="creditPackage"
          className="text-[#6439F5]"
          checked={isSelected}
          onChange={() => onSelect(tokenPackage)}
        />
        <div>
          <h3 className="text-xl font-bold">{tokenPackage.package_name}</h3>
          <div className="flex items-center gap-2">
            <i className="fa fa-coins text-[#6439F5]" />
            <p className="font-bold">{tokenPackage.no_tokens} tokens</p>
          </div>
          <span className="text-sm text-gray-500">One time payment</span>
        </div>
      </div>
      <span className="font-bold text-lg">${tokenPackage.price}</span>
    </label>
  );
};

const Modal = ({ children, onClose, title, tokenModel = false }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
    <div
      className={`bg-white rounded-lg w-full ${
        tokenModel ? "max-w-xl" : ""
      } max-h-[100vh] overflow-y-auto`}
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">{title}</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        {children}
      </div>
    </div>
  </div>
);

const DetailRow = ({ label, value }) => (
  <div className="flex justify-between items-center">
    <span className="text-gray-600">{label}</span>
    <span className="font-medium">{value}</span>
  </div>
);

export default Plans;
