import './LayoutLoader.css';
const LayoutLoader = () => {
    return (
        <div className="three-body">
            <div className="three-body__dot"></div>
            <div className="three-body__dot"></div>
            <div className="three-body__dot"></div>
        </div>
    );
}

export default LayoutLoader;