import { Link, useNavigate } from "react-router-dom";
import useTitle from "../../Hooks/useTitle";
import { useState } from "react";
import axios from "axios";
import Helpers from "../../Config/Helpers";
import GoogleSignIn from "./GoogleSignIn";

const Login = () => {
  useTitle("Login");
  const [showPassword, setShowPassword] = useState(false);

  const defaultUser = {
    email: "",
    password: "",
  };

  const [user, setUser] = useState(defaultUser);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});
    try {
      const response = await axios.post(`${Helpers.apiUrl}auth/login`, user);
      Helpers.toast("success", response.data.message);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("user_id", JSON.stringify(response.data.user.id));
      localStorage.setItem("token", response.data.token);
      const loginTimestamp = new Date().getTime();
      localStorage.setItem("loginTimestamp", loginTimestamp);

      if (response.data.user.user_type === 1) {
        window.location.href = "/admin/dashboard";
      } else {
        window.location.href = "/chat/chat-interface";
      }
    } catch (error) {
      if (error.response) {
        const responseMessage = error.response.data.message;
        const responseCode = error.response.data.code;

        if (responseCode === "EMAIL_NOT_VERIFIED") {
          Helpers.toast("error", responseMessage);
          navigate("/verify-email");
        } else {
          Helpers.toast("error", responseMessage);
          setErrors(error.response.data.errors || {});
        }
      } else {
        Helpers.toast(
          "error",
          "An unexpected error occurred. Please try again."
        );
        console.error("Error during login:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <section className="login-page full-height">
      <div
        className="login-page__shape-1"
        style={{
          backgroundImage: "url(assets/images/shapes/login-page-shape-1.png)",
        }}
      ></div>
      <div className="login-page__shape-2"></div>
      <div className="container">
        <div className="login-page__inner glass">
          <div className="login-page__top">
            <div className="login-page__logo">
              <a href="/login">
                <img src="app/logo-sq-duo.png" className="w100 mx-auto" alt="" />
              </a>
            </div>
          </div>
          <form onSubmit={handleLogin} className="register-one__form">
            <div className="row">
              <div className="col-md-12">
                <div className="register-one_form_email">
                  <input
                    type="email"
                    value={user.email}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                    className="form-control form-control-lg"
                    placeholder="Enter Email Address"
                  />
                  <small className="text-danger">
                    {errors.email ? errors.email[0] : ""}
                  </small>
                </div>
              </div>
              <div className="col-md-12">
                <div className="register-one_form_password position-relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={user.password}
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                    className="form-control form-control-lg"
                    placeholder="Enter Password"
                    required
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="position-absolute"
                    style={{
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    <i
                      className={
                        showPassword ? "fas fa-eye-slash" : "fas fa-eye"
                      }
                    ></i>
                  </span>
                  <small className="text-danger">
                    {errors.password ? errors.password[0] : ""}
                  </small>
                </div>
              </div>
              <div className="col-md-12">
                <Link to={"/forgot-password"} className="c-primary">
                  Forgot Password?
                </Link>
              </div>
              <div className="col-md-12">
                <button
                  type="submit"
                  className="thm-btn register-one__btn"
                  disabled={isLoading}
                >
                  {isLoading ? "Please wait..." : "Login"}
                </button>
              </div>
            </div>
          </form>
          <div className="col-md-12">
            <GoogleSignIn />
          </div>
          <p className="register-one__tagline">
            Don’t have an account?{" "}
            <Link to="/register" className="c-primary">
              Register
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Login;
