import React, { useContext, useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { AnimatePresence } from "framer-motion";
import { KnowledgeBase } from "../../../Components/KnowledgeBase";
import CollectionList from "../../../Components/CollectionList";
import { IoIosRocket } from "react-icons/io";
import { ChatAppContext } from "../../../Context/AppContext";
import { WalletMinimal } from "lucide-react";
import { usePlan } from "../../../Context/PlanContext";

const UsageBar = ({ label, used, total, showUnlimited = false }) => (
  <figure className="mb-3">
    <p className="flex justify-between text-sm mb-1">
      <span className="text-gray-600">{label}</span>
      <span className="text-gray-700">
        {showUnlimited ? "Unlimited" : `${used} / ${total}`}
      </span>
    </p>
    {!showUnlimited && (
      <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
        <div
          className="h-full bg-gradient-to-r from-[#502EC4] to-[#B8DBFC] rounded-full transition-all"
          style={{ width: `${(used / total) * 100}%` }}
        />
      </div>
    )}
  </figure>
);

const Sidebar = ({
  isCollapsed,
  setIsCollapsed,
  isMobile,
  handleUpgradePlan,
}) => {
  const { isKnowledgeBaseOpen, setIsKnowledgeBaseOpen, handleChatModalShow } = useContext(ChatAppContext);
  const { isPlanActive, userData, availableTokens } = usePlan();
  const [planState, setplanState] = useState("");
  useEffect(() => {
    isPlanActive && isPlanActive === "Active"
      ? setplanState("Paid")
      : isPlanActive === "Expired"
      ? setplanState("Expired")
      : setplanState("Free");
  }, [isPlanActive]);
  return (
    <div
      className={`transition-all duration-300 h-screen rounded my-1 
      ${isCollapsed ? "w-[100px]" : "w-[280px]"} 
      ${isMobile ? "fixed inset-0 z-50 w-full my-0" : ""}
      bg-white flex flex-col shadow-md`}
    >
      {/* Mobile Header */}
      {isMobile && (
        <div className="p-4 border-b flex justify-between items-center">
          <h2 className="text-lg font-semibold">Collections</h2>
          <button
            onClick={() => setIsCollapsed(true)}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
      )}

      {/* Top Buttons */}
      <div className="px-4 space-y-3 mt-2">
        <button
          className="btn btn-dark flex items-center gap-2 w-full text-start"
          onClick={() => {
            handleChatModalShow();
          }}
        >
          <FaPlus />
          {!isCollapsed && <span>New</span>}
        </button>
      </div>

      <div className="flex-1 overflow-y-auto px-4 mt-2">
        <CollectionList isCollapsed={isCollapsed} />
      </div>

      <div className="bg-gray-100 mt-auto px-4">
        <hr className="border-gray-200 mb-2" />

        {!isCollapsed && (
          <div className="mb-2">
            <div className="flex items-center gap-2 mb-2">
              <WalletMinimal />
              <h3 className="font-semibold text-gray-800">{planState} Plan</h3>
            </div>

            <div className="space-y-2">
              <UsageBar
                label="Credits"
                used={Math.max(availableTokens, 0)}
                total={Math.max(
                  userData?.org_id
                    ? userData?.organization?.no_tokens
                    : userData?.no_tokens,
                  0
                )}
              />
              {/* <UsageBar label="Team Members" showUnlimited={true} /> */}
            </div>
          </div>
        )}

        <button
          onClick={handleUpgradePlan}
          className="btn btn-dark flex bg-[#502EC4] hover:bg-[#3f25a0] text-white items-center gap-2 w-full p-2 rounded-lg mb-4"
        >
          <IoIosRocket className="shrink-0" />
          {!isCollapsed && <span>Upgrade Plan</span>}
        </button>
      </div>

      <AnimatePresence>
        {isKnowledgeBaseOpen && (
          <KnowledgeBase
            isOpen={isKnowledgeBaseOpen}
            onClose={() => setIsKnowledgeBaseOpen(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Sidebar;
