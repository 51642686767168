import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC9H3V6jFqe-6FNgXEWMkLcgU_hDylfwFA",
  authDomain: "docsphere-3f9f7.firebaseapp.com",
  projectId: "docsphere-3f9f7",
  storageBucket: "docsphere-3f9f7.firebasestorage.app",
  messagingSenderId: "838481735219",
  appId: "1:838481735219:web:df8923c03aaf28b050bd46",
  measurementId: "G-V8XBQ6BDRX",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider, signInWithPopup };
