import { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ChevronLeft,
  User,
  CreditCard,
  Users,
} from "lucide-react";
import Navbar from "../../Components/Navbar";
import Plans from "./Setting/Plans";
import Profile from "./Setting/Profile";
import Organization from "./Setting/Organization";
import Transactions from "./Setting/Transactions";
import axios from "axios";
import Helpers from "../../Config/Helpers";
import dayjs from "dayjs";
import { usePlan } from "../../Context/PlanContext";

const AccountSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [planState, setplanState] = useState("");  

  const logout = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${Helpers.apiUrl}logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        Helpers.toast("success", response.data.message);
        Helpers.authUser = null;
        localStorage.clear();
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const {
    isPlanActive,
    userData,
    getProfileInfo,
  } = usePlan();
  useEffect(() => {
    isPlanActive && isPlanActive === "Active"
      ? setplanState("Paid")
      : isPlanActive === "Expired"
      ? setplanState("Expired")
      : setplanState("Free");
  }, [isPlanActive]);
  const verifyPayment = useCallback(async (trxref, reference) => {
    try {
      const response = await axios.post(
        `${Helpers.apiUrl}verify-payment`, 
        { trxref, reference }, 
        Helpers.authHeaders
      );

      if (response.data.status === "success") {
        const orgName = localStorage.getItem("organizationName");
        const actionType = localStorage.getItem("actionType");
        const selectedPlan = JSON.parse(localStorage.getItem("selectedPlan"));
        const currentDate = dayjs().format("YYYY-MM-DD");

        const data = {
          user_id: Helpers.authUserId,
          package_id: selectedPlan.id,
          amount: selectedPlan.package_price,
          purchase_date: currentDate,
          org_name: orgName ? orgName : undefined,
          token_purchase: actionType === 'buyTokens' ? true : false
        };

        try {
          const transactionResponse = await axios.post(
            `${Helpers.apiUrl}transactions/save-transaction`,
            data,
            Helpers.authHeaders
          );
          
          Helpers.toast(
            "success", 
            data.token_purchase ? 
              transactionResponse.data.message : 
              "Plan selected successfully"
          );
          
          await getProfileInfo();
          navigate("/chat/chat-interface");
        } catch (error) {
          Helpers.toast("error", error.response.data.message);
        }
      } else {
        Helpers.toast("error", "Payment verification failed");
      }
    } catch (error) {
      Helpers.toast("error", "Error verifying payment");
      console.error("Error verifying payment:", error.message);
    } finally {
      localStorage.removeItem("actionType");
      localStorage.removeItem("organizationName");
      localStorage.removeItem("selectedPlan");
    }
  },[navigate, getProfileInfo]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const trxref = query.get("trxref");
    const reference = query.get("reference");

    if (trxref && reference) {
      verifyPayment(trxref, reference);
    }
  }, [location,verifyPayment]);

  const [activeSection, setActiveSection] = useState(() => {
    const storedTab = localStorage.getItem("activeTab");
    localStorage.removeItem("activeTab");
    return storedTab || "profile";
  });

  const sidebarItems = [
    { id: "profile", label: "Profile", icon: User },
    { id: "plan", label: "Plan", icon: CreditCard },
    ...(userData?.is_org_owner
      ? [{ id: "organization", label: "Organization Members", icon: Users }]
      : []),
    { id: "transactions", label: "Transactions History", icon: CreditCard },
  ];  

  const renderContent = () => {
    switch (activeSection) {
      case "profile":
        return <Profile />;
      case "plan":
        return <Plans/>;
      case "organization":
        return <Organization />;
      case "transactions":
        return <Transactions />;
      default:
        return null;
    }
  };

  return ( 
    <div className="min-h-screen bg-white flex flex-col">
      <Navbar 
        planState={planState} disable={true} 
        onLogout={logout}
        />

      <div className="flex flex-col md:flex-row flex-1">
        <div className="w-full md:w-[280px] border-b md:border-b-0 md:border-r">
          <div className="p-4 md:p-6">
            <button
              onClick={() => navigate(-1)}
              className="flex items-center gap-2 mb-6 md:mb-8 text-gray-800"
            >
              <ChevronLeft className="h-5 w-5" />
              <span className="text-lg">Account Settings</span>
            </button>

            <nav className="space-y-1 flex flex-col md:block overflow-x-auto pb-4 md:pb-0">
              {sidebarItems.map((item) => {
                const Icon = item.icon;
                return (
                  <button
                    key={item.id}
                    onClick={() => setActiveSection(item.id)}
                    className={`flex-shrink-0 md:w-full flex items-center gap-3 px-4 py-2 rounded-lg text-left
                      ${
                        activeSection === item.id
                          ? "bg-[#F8F9FB]"
                          : "hover:bg-gray-50"
                      }`}
                  >
                    <Icon className="h-5 w-5 text-gray-600" />
                    <span className="text-wrap">{item.label}</span>
                  </button>
                );
              })}
            </nav>
          </div>
        </div>
        <div className="flex-1 p-8">{renderContent()}</div>
      </div>
    </div>
  );
};

export default AccountSettings;
