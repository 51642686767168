import React, { useEffect, useState } from "react";
import axios from "axios";
import Helpers from "../../../Config/Helpers";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const getUserTransactions = () => {
    axios
      .get(`${Helpers.apiUrl}transactions/user`, Helpers.authHeaders)
      .then((response) => {
        setTransactions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
      });
  };
  useEffect(() => {
    getUserTransactions();
  }, []);

  return (
    <div className="w-full overflow-x-auto">
      <div className="flex justify-between items-center mb-1 my-auto">
        <h2 className="text-xl font-semibold">Transactions History</h2>
      </div>
      <div className="min-w-full shadow rounded-lg overflow-hidden">
        {transactions.length === 0 ? (
          <p className="text-center py-4 text-gray-500">
            No transactions found.
          </p>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">
                  ID
                </th>
                <th className="px-4 py-3 text-left text-sm font-medium text-gray-700 hidden md:table-cell">
                  Package
                </th>
                <th className="px-4 py-3 text-left text-sm font-medium text-gray-700 hidden sm:table-cell">
                  Purchase Date
                </th>
                <th className="px-4 py-3 text-left text-sm font-medium text-gray-700 hidden lg:table-cell">
                  Expiry Date
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {transactions.map((transaction,index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-4 py-3 whitespace-nowrap">
                    <div className="flex flex-col">
                      <div className="text-sm font-medium text-gray-900">
                        #{index+1}
                      </div>
                      <div className="text-sm text-gray-500 md:hidden">
                        {transaction.package_type}
                      </div>
                      <div className="text-sm text-gray-500 sm:hidden">
                        {transaction.purchase_date}
                      </div>
                      <div className="text-sm text-gray-500 lg:hidden">
                        {transaction.expiry_date}
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap hidden md:table-cell">
                    <div className="text-sm text-gray-900">
                      {transaction.package_type}
                    </div>
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap hidden sm:table-cell">
                    <div className="text-sm text-gray-900">
                      {transaction.purchase_date}
                    </div>
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap hidden lg:table-cell">
                    <div className="text-sm text-gray-900">
                      {transaction.expiry_date}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Transactions;
