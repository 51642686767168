import { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { OpenAIEmbeddings } from "@langchain/openai";

import { useApi } from '../Context/ApiContext';

export const useRetriever = (selectedDocs = []) => {
  const { apiSettings } = useApi();
  const [retriever, setRetriever] = useState(null);
  useEffect(() => {

    if (!apiSettings.isLoading && apiSettings.openAiApiKey && apiSettings.supabaseApiKey && apiSettings.supabaseUrl && selectedDocs?.length > 0) {

      
      try {
        const embeddings = new OpenAIEmbeddings({
          openAIApiKey: apiSettings.openAiApiKey
        });
        
        const client = createClient(apiSettings.supabaseUrl, apiSettings.supabaseApiKey);
        const contentids = selectedDocs;

        const customRetriever = {
          getRelevantDocuments: async (query) => {
            const queryEmbedding = await embeddings.embedQuery(query);
            const { data: documents } = await client.rpc('match_docsphere_docus', {
              query_embedding: queryEmbedding,
              contentids: contentids,
            });

            if (documents) {
              return documents;
            } else {
              console.error("No documents found for the query.");
              return [];
            }
          }
        };
        
        setRetriever(customRetriever);
      } catch (error) {
        console.error("Error initializing retriever:", error);
      }
    }
  }, [apiSettings, selectedDocs]);

  return retriever;
};
